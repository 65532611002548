export const getQuestionnaireClaims = (questionnaire, questionnaireMatrix) => {
  const claims = Object.keys(questionnaire)
    .filter((k) => questionnaire[k].value === 'Yes')
    .map((qId) => {
      const answers = questionnaire[qId];
      const question = questionnaireMatrix.find((q) => q.id === qId);
      const claim = {
        id: qId,
        keywords: question.subtitle
          ? question.subtitle.split(',')
          : question.keywords,
      };

      if (!question.subQuestions) {
        return claim;
      }

      claim.keywords = question.subQuestions
        .filter(
          (sq) =>
            answers.selections[sq.id] && answers.selections[sq.id] === 'Yes'
        )
        .map((sq) => sq.question);
      return claim;
    });

  return claims;
};
