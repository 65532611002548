import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { HMSRoomProvider } from '@100mslive/react-sdk';
import moment from 'moment-timezone';

import { UserProvider } from './contexts/UserContext';
import MainRouter from './components/routers/MainRouter';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const queryClient = new QueryClient();

moment.tz.setDefault(moment.tz.guess());

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <HMSRoomProvider>
          <MainRouter />
          <ToastContainer />
        </HMSRoomProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
