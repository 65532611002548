import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { components } from 'react-select';
import axios from 'axios';

import dayjs from '../../lib/day';

const Input = (props) => {
  return <components.Input {...props} />;
};

const Option = (props) => {
  const record = props.data.record;

  return (
    <components.Option {...props}>
      <p className="mb-0">{props.children}</p>
      <small className="text-muted">
        Last Updated On {dayjs(record.claimUpdatedOn).format('MM/DD/YYYY')}
      </small>
    </components.Option>
  );
};

const ClaimSelect = ({
  value,
  isMulti = false,
  onChange,
  showDefaultOptions = false,
}) => {
  const loadOptions = async (term) => {
    const results = await axios.get(
      `/claims?search=${term}&sortBy=claimUser.userLastName&sortDir=ASC`
    );
    return results.data.rows.map((item) => {
      return {
        value: item.claimId,
        label: `${item.userLastName}, ${item.userFirstName}`,
        record: item,
      };
    });
  };
  return (
    <Async
      components={{ Option, Input }}
      isMulti={isMulti}
      isClearable={true}
      isSearchable={true}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      defaultOptions={showDefaultOptions}
    />
  );
};

ClaimSelect.propTypes = {
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ClaimSelect;
