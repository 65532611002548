import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faInfoCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../../hooks/useForm';
import {
  useUpsertMeetingInvite,
  useDeleteMeetingInvite,
} from '../../../services/calls';
import ClientSelect from '../../common/ClientSelect';

const StyledText = styled.p`
  font-weight: 700;
`;

const DEFAULT_VALUES = {
  meetingInviteId: null,
  adminUserId: null,
  userId: null,
  date: '',
  time: '',
  length: '0.5',
  details: '',
};

const MeetingInviteModal = ({ show, toggle, meetingInvite, onSuccess }) => {
  const { form, onChange, setValues } = useForm({ ...DEFAULT_VALUES });

  const upsert = useUpsertMeetingInvite(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const deleteInvite = useDeleteMeetingInvite(
    () => {
      toast.success(
        'The meeting invite was successfully deleted and the calendar event canceled.'
      );
      onSuccess();
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  useEffect(() => {
    if (meetingInvite && meetingInvite.meetingInviteId) {
      setValues(
        {
          userId: {
            label: `${meetingInvite.clientUserLastName}, ${meetingInvite.clientUserFirstName}`,
            value: meetingInvite.clientUserId,
            record: { email: 'N/A' },
          },
          date: moment(meetingInvite.meetingInviteDate).format('YYYY-MM-DD'),
          time: moment(meetingInvite.meetingInviteDate).format('HH:mm'),
          length: meetingInvite.meetingInviteLength.toString(),
          details: meetingInvite.meetingInviteNotes || '',
        },
        true
      );
    } else if (meetingInvite) {
      const opts = { ...DEFAULT_VALUES, ...meetingInvite };

      if (opts.meetingInviteDate) {
        opts.date = moment(meetingInvite.meetingInviteDate).format(
          'YYYY-MM-DD'
        );
        opts.time = moment(meetingInvite.meetingInviteDate).format('HH:mm');
      }

      setValues({ ...opts }, true);
    } else {
      setValues({ ...DEFAULT_VALUES }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingInvite]);

  const onSubmit = (e) => {
    e.preventDefault();

    const dt = moment(`${form.date} ${form.time}`, 'YYYY-MM-DD HH:mm');

    upsert.mutate({
      meetingInviteId: meetingInvite ? meetingInvite.meetingInviteId : null,
      payload: {
        userId: form.userId ? form.userId.value : null,
        date: dt.format(),
        length: Number(form.length),
        notes: form.details || null,
      },
    });
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h2>
          {meetingInvite && meetingInvite.meetingInviteId
            ? 'Edit Meeting Invite'
            : 'New Meeting Invite'}
        </h2>
        <hr />
        <form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Client</Form.Label>
            {meetingInvite && meetingInvite.meetingInviteId ? (
              <>
                <StyledText>
                  {meetingInvite.clientUserLastName},{' '}
                  {meetingInvite.clientUserFirstName}
                </StyledText>
                <StyledText>{meetingInvite.clientUserEmail}</StyledText>
              </>
            ) : (
              <ClientSelect
                value={form.userId}
                onChange={(vals) =>
                  onChange({ target: { name: 'userId', value: vals } })
                }
                required
              />
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={form.date}
                  onChange={onChange}
                  placeholder="Meeting Date"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="time"
                  name="time"
                  value={form.time}
                  onChange={onChange}
                  placeholder="Meeting Time"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Length</Form.Label>
                <Form.Select
                  name="length"
                  value={form.length}
                  onChange={onChange}
                  required
                >
                  <option value="0.5">30 Minutes</option>
                  <option value="1">1 Hour</option>
                  <option value="1.5">1.5 Hours</option>
                  <option value="2">2 Hours</option>
                  <option value="2.5">2.5 Hours</option>
                  <option value="3">3 Hours</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Details/Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="details"
              value={form.details}
              onChange={onChange}
              placeholder="Details/Notes"
              style={{ minHeight: 200 }}
            />
            <p className="text-muted mt-2 mb-3">
              <FontAwesomeIcon icon={faInfoCircle} /> This will be included in
              the comments/details sent to the user.
            </p>
          </Form.Group>
          {meetingInvite && meetingInvite.meetingInviteId ? (
            <div className="mb-3">
              <Form.Label>How To Join Your Meeting</Form.Label>
              <p>
                <Link
                  to={`/meeting/${meetingInvite.meetingInviteId}`}
                >{`${window.location.origin}/meeting/${meetingInvite.meetingInviteId}`}</Link>
              </p>
            </div>
          ) : null}
          <hr />
          {meetingInvite && meetingInvite.meetingInviteId ? (
            <div className="float-end">
              <Button
                variant="link"
                className="text-danger"
                onClick={() =>
                  deleteInvite.mutate(meetingInvite.meetingInviteId)
                }
                disabled={deleteInvite.isLoading}
              >
                Delete
              </Button>
            </div>
          ) : null}
          <Button variant="success" type="submit" disabled={upsert.isLoading}>
            <FontAwesomeIcon
              icon={upsert.isLoading ? faSpinner : faCheck}
              spin={upsert.isLoading}
            />{' '}
            Save
          </Button>{' '}
          <Button variant="link" onClick={() => toggle()}>
            Cancel
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default MeetingInviteModal;
