import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useUser } from '../../contexts/UserContext';

const PrivateRoute = ({
  as: Component,
  admin = false,
  provider = false,
  ...props
}) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    return (
      <Navigate to="/sign-in" state={{ from: location.pathname }} replace />
    );
  }

  if (admin && provider) {
    if (user.userType === 'client') {
      return <Navigate to="/dashboard" />;
    }
  } else if (admin && user.userType !== 'admin') {
    return <Navigate to="/dashboard" />;
  } else if (provider && user.userType !== 'provider') {
    return <Navigate to="/dashboard" />;
  }

  return <Component {...props} />;
};

export default PrivateRoute;
