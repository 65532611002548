import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faComments,
  faFileContract,
  faPuzzlePiece,
  faListCheck,
  faEdit,
  faTrash,
  faNoteSticky,
  faPhone,
  faEnvelope,
  faBell,
  faTasksAlt,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import nl2br from 'react-nl2br';

import { RoundButton } from './Profile.elements';

import 'react-vertical-timeline-component/style.min.css';

const ActivityTab = ({ claim, activity, onActivityAction }) => {
  const renderUserActivity = (row) => {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="mt-0 pt-0">
            {row.userFirstName} added a {row.actType}:
          </p>
          <div className="d-flex gap-1">
            {['task', 'reminder'].includes(row.actType) &&
            !row.actCompletedOn ? (
              <RoundButton
                variant="link"
                size="sm"
                className="btn-round"
                onClick={() => onActivityAction('complete', row)}
              >
                <FontAwesomeIcon icon={faCheck} />
              </RoundButton>
            ) : null}
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => onActivityAction('edit', row)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </RoundButton>
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => onActivityAction('delete', row)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </RoundButton>
          </div>
        </div>
        <div
          style={{
            margin: '0',
            marginBottom: '1rem',
            padding: '0.5rem 1rem',
            borderLeft: '3px solid #2196F3',
          }}
        >
          {nl2br(row.actComments)}
        </div>
        {row.actReminderOn || row.actCompletedOn ? (
          <div className="d-flex justify-content-between align-items-center">
            {row.actReminderOn ? (
              <p className="mt-0 pt-0 text-muted">
                Remind On: {moment.utc(row.actReminderOn).format('MM/DD/YYYY')}
              </p>
            ) : null}
            {row.actCompletedOn ? (
              <p className="mt-0 pt-0 text-muted">
                Completed on {moment(row.actCompletedOn).format('MM/DD/YYYY')}{' '}
                by {row.completedByFirstName}
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <VerticalTimeline layout="1-column-left">
      {activity.map((row, idx) => {
        let label = row.type;
        let icon = <FontAwesomeIcon icon={faFile} />;

        switch (row.type) {
          case 'file:created':
            label = `${row.userFirstName} ${row.userLastName} added file ${row.fileName}.`;
            break;
          case 'file:updated':
            label = `${row.userFirstName} ${row.userLastName} updated file ${row.fileName}.`;
            break;
          case 'transcript:created':
            label = `Video meeting transcript was created.`;
            icon = <FontAwesomeIcon icon={faComments} />;
            break;
          case 'transcript:updated':
            label = `Video meeting transcript was updated.`;
            icon = <FontAwesomeIcon icon={faComments} />;
            break;
          case 'claim:created':
            label = `Claim created.`;
            icon = <FontAwesomeIcon icon={faFileContract} />;
            break;
          case 'addon:created':
            const type = row.claimAddonType.split(':');
            label =
              type[1] === 'analysis'
                ? `Transcript analysis created`
                : `Addon created for ${type[2]}`;
            icon = <FontAwesomeIcon icon={faPuzzlePiece} />;
            break;
          case 'task:completed':
            label = `Task: ${row.claimTaskLabel} completed`;
            icon = <FontAwesomeIcon icon={faListCheck} />;
            break;

          case 'user:activity:note':
            icon = <FontAwesomeIcon icon={faNoteSticky} />;
            label = renderUserActivity(row);
            break;
          case 'user:activity:call':
            icon = <FontAwesomeIcon icon={faPhone} />;
            label = renderUserActivity(row);
            break;
          case 'user:activity:email':
            icon = <FontAwesomeIcon icon={faEnvelope} />;
            label = renderUserActivity(row);
            break;
          case 'user:activity:reminder':
            icon = <FontAwesomeIcon icon={faBell} />;
            label = renderUserActivity(row);
            break;
          case 'user:activity:task':
            icon = <FontAwesomeIcon icon={faTasksAlt} />;
            label = renderUserActivity(row);
            break;
          case 'user:activity:other':
            label = renderUserActivity(row);
            break;

          default:
          // do nothing
        }

        return (
          <VerticalTimelineElement
            key={idx}
            className="vertical-timeline-element--work"
            date={row.date}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={icon}
          >
            <p style={{ margin: 0 }}>{label}</p>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default ActivityTab;
