import React, { useEffect, useMemo, useState } from 'react';
import { Form, Table, Button, Modal } from 'react-bootstrap';
import numeral from 'numeral';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mkConfig, generateCsv, asBlob } from 'export-to-csv';
import {
  faCheck,
  faFileArrowDown,
  faGear,
} from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../../../contexts/UserContext';
import dayjs from '../../../../lib/day';
import AdminSelect from '../../../common/AdminSelect';
import { useGenerateReport } from '../../../../services/reports';
import { capitalizeFirstLetter } from '../../../../lib/strings';
import {
  AGING_REPORT_HEADERS,
  OPEN_REPORT_HEADERS,
  OUTSTANDING_INVOICES_HEADERS,
  TIMELINE_REPORT_HEADERS,
} from '../../../../lib/constants';
import CustomDropdown from '../../../common/CustomDropdown';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const StyledSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const DATE_FORMAT = 'MMMM D, YYYY h:mm A';

const CsvConfig = mkConfig({
  columnHeaders: Object.entries(AGING_REPORT_HEADERS).map(([key, value]) => ({
    key,
    displayLabel: value,
  })),
});

const REPORT_HEADERS = {
  aging: AGING_REPORT_HEADERS,
  outstanding: OUTSTANDING_INVOICES_HEADERS,
  open: OPEN_REPORT_HEADERS,
  timeline: TIMELINE_REPORT_HEADERS,
};

const Toolbar = ({
  data,
  total,
  form,
  onChange,
  onSubmit,
  columns,
  view,
  onViewChange,
}) => {
  const { user } = useUser();
  const [reportType, setReportType] = useState(undefined);
  const [reportViewed, setReportViewed] = useState(false);
  const [show, setShow] = useState(false);

  const { data: report } = useGenerateReport(reportType);

  const [minValue, maxValue] = useMemo(() => {
    let min = 0;
    let max = 0;

    data.forEach((i) => {
      min += i._minValue;
      max += i._maxValue;
    });

    return [min, max];
  }, [data]);

  const viewCols = useMemo(() => {
    return columns.map((c) => {
      const isChecked = view.includes(c.id);

      return {
        label: (
          <>
            {isChecked ? (
              <small className="me-2">
                <FontAwesomeIcon icon={faCheck} fixedWidth />
              </small>
            ) : null}
            <span>{c.label}</span>
          </>
        ),
        onClick: () => {
          if (isChecked) {
            onViewChange(view.filter((v) => v !== c.id));
          } else {
            onViewChange([...view, c.id]);
          }
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleDownloadReport = () => {
    const csv = generateCsv(CsvConfig)(report.results);
    const blob = asBlob(CsvConfig)(csv);
    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute(
      'download',
      `${reportType}-report-${dayjs().format('YYYY-MM-DD')}.csv`
    );
    tempLink.click();
  };

  const handleModalClose = () => {
    setShow(false);
    setReportViewed(true);
  };

  useEffect(() => {
    if (!report) return;
    if (show) return;
    if (reportViewed) return;
    setShow(true);
  }, [report, show, reportViewed]);

  return (
    <StyledWrapper className="mb-2">
      <form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Control
            name="search"
            value={form.search || ''}
            onChange={onChange}
            placeholder="Search..."
          />
        </Form.Group>
      </form>
      <StyledSection className="gap-2 fw-bold">
        <div className="d-flex justify-content-end gap-2 fw-bold">
          <span>
            {numeral(minValue).format('$0,0')} -{' '}
            {numeral(maxValue).format('$0,0')}
          </span>
          <span>&middot;</span>
          <span>
            {total} {pluralize('item', total)}
          </span>
        </div>
        <div className="d-none">
          <Form.Group>
            <Form.Select
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="">View Report</option>
              <option value="aging">Aging Report</option>
              <option value="outstanding">Outstanding Invoices Report</option>
              <option value="open">Open Cases Report</option>
              <option value="timeline">Timeline Report</option>
            </Form.Select>
          </Form.Group>
        </div>
        {user._admin && user._admin.adminRole === 'admin' ? (
          <div>
            <AdminSelect
              value={form.user || null}
              onChange={(val) =>
                onChange({ target: { name: 'user', value: val } })
              }
              isMulti={false}
              showDefaultOptions={true}
              style={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: '225px',
                  fontWeight: 500,
                }),
              }}
            />
          </div>
        ) : null}
        <CustomDropdown
          label={<FontAwesomeIcon icon={faGear} fixedWidth />}
          variant="link"
          menuItems={viewCols}
          className="text-dark"
        />
      </StyledSection>
      <Modal size="xl" show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${capitalizeFirstLetter(
            reportType
          )} Report - ${dayjs().format(DATE_FORMAT)}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                {reportType
                  ? Object.values(REPORT_HEADERS[reportType || 'aging']).map(
                      (header, index) => (
                        <th
                          className={`${index === 0 ? 'sr-only' : ''}`}
                          key={index}
                        >
                          {header}
                        </th>
                      )
                    )
                  : null}
              </tr>
            </thead>
            <tbody>
              {report && report.results
                ? report.results.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, index) => (
                        <td
                          className={`${index === 0 ? 'sr-only' : ''}`}
                          key={index}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleDownloadReport}>
            <FontAwesomeIcon className="mx-2" icon={faFileArrowDown} />
            Download Report
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledWrapper>
  );
};

export default Toolbar;
