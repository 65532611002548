import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import ErrorFallback from '../errorBoundary/ErrorFallback';

import './themes/Primary.css';

const StyledLayout = styled.div`
  background: rgb(191, 191, 191);
`;

const StyledHeader = styled.header`
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.9);
`;

const PublicLayout = ({ children }) => {
  return (
    <StyledLayout>
      <StyledHeader>
        <img
          src={process.env.PUBLIC_URL + '/VR-Full-White-Horizontal-01.png'}
          alt="Veritas Ratings"
          title="Veritas Ratings"
          style={{ height: 120 }}
        />
      </StyledHeader>
      <section>
        <ErrorBoundary fallback={<ErrorFallback />}>
          <Container>{children}</Container>
        </ErrorBoundary>
      </section>
      <footer>
        <p className="text-center mt-5">
          &copy; {dayjs().format('YYYY')} Veritas Ratings &middot; All Rights
          Reserved &middot; <Link to="/privacy">Privacy Policy</Link> &middot;{' '}
          <Link to="/terms">Terms of Use</Link>
        </p>
      </footer>
    </StyledLayout>
  );
};

export default PublicLayout;
