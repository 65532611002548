import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

import CustomDropdown from '../../../common/CustomDropdown';

const StyledItem = styled.div`
  width: 100%;
  padding: 0.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  box-shadow: 0px 10px 4px -8px rgba(0, 0, 0, 0.1);
  color: rgba(33, 37, 41, 0.75);

  & a {
    text-decoration: none;
  }

  & strong {
    color: #000;
  }
`;

const LeadCard = ({ lead, className, onSelect, onAssignTo, onDelete }) => {
  const daysSince = moment().diff(moment(lead.leadUpdatedOn), 'days');

  return (
    <StyledItem className={className || ''}>
      <div className="d-flex justify-content-between align-items-start">
        <Button
          variant="link"
          className="m-0 p-0 mb-1 fs-5"
          style={{ textDecoration: 'none' }}
          onClick={() => onSelect(lead)}
        >
          <strong>
            {lead.leadFirstName} {lead.leadLastName}
          </strong>
        </Button>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <OverlayTrigger
          overlay={
            <Tooltip id={`${lead.leadId}-value`}>
              {lead.leadAssignedTo
                ? `Lead assigned to ${lead.assignedFirstName} ${lead.assignedLastName}`
                : 'Lead is unassigned.'}
            </Tooltip>
          }
        >
          <p
            className={
              lead.leadAssignedTo ? `text-muted mb-2` : `text-danger mb-2`
            }
          >
            <FontAwesomeIcon icon={faUserTie} />{' '}
            {lead.leadAssignedTo
              ? `${lead.assignedFirstName} ${lead.assignedLastName}`
              : 'Unassigned'}
          </p>
        </OverlayTrigger>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span className={daysSince >= 7 ? 'text-danger' : ''}>
          <OverlayTrigger
            overlay={
              <Tooltip id={`${lead.leadId}-update`}>
                Last updated on{' '}
                {moment(lead.leadUpdatedOn).format('MM/DD/YYYY')}.
              </Tooltip>
            }
          >
            <span>
              {daysSince} {pluralize('day', daysSince)} ago
            </span>
          </OverlayTrigger>
        </span>
        <CustomDropdown
          menuItems={[
            {
              label: 'Assign To',
              onClick: () => onAssignTo(),
            },
            {
              divider: true,
            },
            {
              label: 'Delete Lead',
              onClick: () => {
                if (
                  window.confirm('Are you sure you want to delete this lead?')
                ) {
                  onDelete();
                }
              },
            },
          ]}
          clickData={lead}
        />
      </div>
    </StyledItem>
  );
};

export default LeadCard;
