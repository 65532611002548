import React, { useEffect, useState } from 'react';
import {
  useHMSActions,
  useHMSStore,
  selectIsConnectedToRoom,
} from '@100mslive/react-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUser } from '../../contexts/UserContext';
import { useCreateCall } from '../../services/calls';
import useEffectOnce from '../../hooks/useEffectOnce';
import PrivateLayout from '../layouts/PrivateLayout';
import Loading from '../common/Loading';

import InConference from './components/InConference';
import PreviewConference from './components/PreviewConference';

const Call = () => {
  const { user } = useUser();
  const { inviteId } = useParams();
  const [data, setData] = useState(null);
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const navigate = useNavigate();

  const joinConference = async () => {
    await hmsActions.join({
      userName: `${user.userFirstName} ${user.userLastName}`,
      authToken: data.authToken,
      settings: {
        isAudioMuted: false,
        isVideoMuted: false,
      },
      rememberDeviceSelection: true,
    });
  };

  const call = useCreateCall(
    async (res) => {
      // use room code to fetch auth token
      const authToken = await hmsActions.getAuthTokenByRoomCode({
        roomCode: res.roomCode,
      });

      setData({ ...res, authToken });
    },
    { onError: (err) => toast.error(err.message) }
  );

  const leaveConference = async () => {
    await hmsActions.leave();
    navigate('/dashboard');
  };

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  useEffectOnce(() => {
    call.mutate(inviteId);
  });

  const renderView = () => {
    if (isConnected) {
      return <InConference onEndCall={() => leaveConference()} />;
    }

    if (data && data.roomCode) {
      return (
        <PreviewConference
          withUser={data.invite}
          authToken={data.authToken}
          onJoin={() => joinConference()}
          onLeave={() => leaveConference()}
        />
      );
    }

    return <Loading msg="Preparing your call..." />;
  };

  return <PrivateLayout>{renderView()}</PrivateLayout>;
};

export default Call;
