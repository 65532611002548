import { useVideo } from '@100mslive/react-sdk';

const Peer = ({ peer }) => {
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  });

  return (
    <div className="w-100">
      <video
        ref={videoRef}
        className={`${peer.isLocal ? 'local' : ''}`}
        autoPlay
        muted
        playsInline
      />
      <div>
        {peer.name} {peer.isLocal ? '(You)' : ''}
      </div>
    </div>
  );
};

export default Peer;
