import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import TranscriptEditor from './TranscriptEditor';

const TranscriptsTab = ({ claim, transcripts, questionnaireMatrix }) => {
  const [transcript, setTranscript] = useState(null);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          {!transcripts || transcripts.length === 0 ? (
            <p className="text-center lead">No transcripts are available.</p>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Confidence</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {transcripts.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {moment(row.meetingDatetime).format(
                          'MM/DD/YYYY h:mm A'
                        )}
                      </td>
                      <td>
                        {row.transcriptMetadata
                          ? row.transcriptMetadata.confidence
                          : 'N/A'}
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => setTranscript(row)}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            fixedWidth
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      <TranscriptEditor
        show={transcript !== null}
        toggle={() => setTranscript(null)}
        claim={claim}
        transcript={transcript}
        questionnaireMatrix={questionnaireMatrix}
      />
    </>
  );
};

export default TranscriptsTab;
