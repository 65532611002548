import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateTranscript } from '../../../services/claims';
import { getQuestionnaireClaims } from '../../../lib/questionnaireHelpers';

const StyledContentArea = styled.div`
  border: 1px solid #ccc;
  padding: 1rem;
  background: #fdfdfd;
  max-height: 90vh;
  overflow: scroll;
  overflow-x: hidden;

  & code {
    background: #000;
    color: #fff;
    font-weight: 700;
  }

  & strong {
    background: yellow;
    font-weight: 500;
  }
`;

const SectionEditor = styled.div`
  max-height: 90vh;
  overflow: auto;
  overflow-x: hidden;

  & > div {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }

  & textarea {
    min-height: 200px;
  }
`;

const TranscriptEditor = ({
  show,
  toggle,
  claim,
  questionnaireMatrix,
  transcript,
}) => {
  const [content, setContent] = useState({});
  const [keywords, setKeywords] = useState([]);
  const queryClient = useQueryClient();

  const updateTranscript = useUpdateTranscript(
    () => {
      queryClient.invalidateQueries('claim-profile');
      toast.success('Your transcript content has been successfully updated.');
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    if (!claim) {
      setKeywords([]);
      return;
    }

    let newKeywords = [];
    const claims = getQuestionnaireClaims(
      claim.questionnaire,
      questionnaireMatrix
    );
    claims.forEach((c) => {
      newKeywords = [...newKeywords, ...c.keywords];
    });

    setKeywords(newKeywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim]);

  useEffect(() => {
    if (!transcript) {
      setContent({});
    } else {
      setContent(transcript.transcriptContent);
    }
  }, [transcript]);

  const renderHighlightedContent = (rawText) => {
    const reg = new RegExp(`\\s+(${keywords.join('|')})[.?!-\\s]+`, 'gim');
    const regBold = new RegExp(
      `\\s+(${[
        'combat',
        'explosion',
        'ied',
        'bomb',
        'firefight',
        'tbi',
        'injure',
        'injury',
        'pain',
      ].join('|')})[.?!-\\s]+`,
      'gim'
    );
    return rawText.replace(reg, ' `$1` ').replace(regBold, ' **$1** ');
  };

  if (!transcript) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => toggle()} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Transcript Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="h-100">
          <Col xs={12} sm={8} className="h-100">
            <StyledContentArea>
              <Markdown>
                {renderHighlightedContent(transcript.transcriptRawText)}
              </Markdown>
            </StyledContentArea>
          </Col>
          <Col xs={12} sm={4}>
            <SectionEditor>
              {keywords.map((keyword, idx) => {
                return (
                  <div key={idx}>
                    <h5>Symptom: {keyword}</h5>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as={'textarea'}
                        placeholder="Copy/paste sections of the transcript here..."
                        value={content[keyword] || ''}
                        onChange={(e) => {
                          setContent({ ...content, [keyword]: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </div>
                );
              })}
              <Button
                variant="primary"
                onClick={() => {
                  updateTranscript.mutate({
                    claimId: claim.claimId,
                    transcriptId: transcript.transcriptId,
                    payload: {
                      transcriptContent: {
                        ...content,
                      },
                    },
                  });
                }}
                disabled={updateTranscript.isLoading}
              >
                Save Content
              </Button>
            </SectionEditor>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TranscriptEditor;
