import React, { useEffect } from 'react';
import { Container, Alert, Button } from 'react-bootstrap';
import { removeCookie } from '../../lib/cookies';

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    if (error) {
      alert('Error triggered');
    }
  }, [error]);

  return (
    <Container>
      <Alert variant="danger">
        <h1>Oh no!</h1>
        <p>
          It looks like we encountered an error. Please use the button below to
          reset your experience and try again.
        </p>
        <p>
          <Button
            variant="danger"
            onClick={() => {
              window.localStorage.clear();
              window.sessionStorage.clear();
              removeCookie('token');
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        </p>
      </Alert>
    </Container>
  );
};

export default ErrorFallback;
