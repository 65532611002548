import React, { useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import get from 'lodash.get';

import Panel from '../common/Panel';
import { useForm } from '../../hooks/useForm';

const RowWrapper = styled.div`
  & > div {
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    margin-bottom: 6px;
  }

  & > div:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const QuestionnaireStep = ({ question, values = {}, onNext, onBack }) => {
  const { form, onChange, setValues } = useForm({});

  useEffect(() => {
    if (!question) {
      return;
    }

    const vals = {
      value: get(values, 'value', null),
    };

    if (question.subQuestions) {
      vals.selections = get(values, 'selections', {});

      question.subQuestions.forEach((s) => {
        vals.selections[s.id] = get(values, `selections[${s.id}]`, null);
      });
    }

    setValues(vals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const onSubmit = (e) => {
    e.preventDefault();
    onNext({ ...form }, question.id);
  };

  const renderSubQuestions = () => {
    // don't render if we don't have any subquestions
    if (!question.subQuestions) {
      return null;
    }

    // also, don't render if the user selected no on the primary question or haven't selected anything yet
    if (!form.value || form.value === 'No') {
      return null;
    }

    return (
      <Panel className="mb-3 pb-3">
        <RowWrapper>
          {question.subQuestions.map((sub, subIdx) => {
            const value = get(form, `selections[${sub.id}]`, null);

            return (
              <div key={subIdx}>
                <Row>
                  <Col xs={8}>{sub.question}</Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      id={`value-${question.id}-${sub.id}-yes`}
                      name={`selections[${sub.id}]`}
                      label="Yes"
                      value="Yes"
                      onChange={onChange}
                      checked={value === 'Yes'}
                      required
                      inline
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      id={`value-${question.id}-${sub.id}-no`}
                      name={`selections[${sub.id}]`}
                      label="No"
                      value="No"
                      onChange={onChange}
                      checked={value === 'No'}
                      required
                      inline
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </RowWrapper>
      </Panel>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Panel className="mb-3 pb-3">
        <h5>{question.question}</h5>
        {question.subtitle ? (
          <p className="text-muted">{question.subtitle}</p>
        ) : null}

        <Form.Group>
          <Form.Check
            type="radio"
            id={`value-${question.id}-yes`}
            name="value"
            label="Yes"
            value="Yes"
            onChange={onChange}
            checked={form.value === 'Yes'}
            required
          />
          <Form.Check
            type="radio"
            id={`value-${question.id}-no`}
            name="value"
            label="No"
            value="No"
            onChange={onChange}
            checked={form.value === 'No'}
            required
          />
        </Form.Group>
      </Panel>

      {renderSubQuestions()}

      <Panel className="pb-3">
        {typeof onBack === 'function' ? (
          <Button variant="primary" onClick={() => onBack()} className="me-3">
            <FontAwesomeIcon icon={faChevronLeft} /> Back
          </Button>
        ) : null}

        <Button type="submit" variant="primary">
          Next <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Panel>
    </form>
  );
};

export default QuestionnaireStep;
