import React from 'react';
import { useHMSActions, useHMSStore, selectPeers } from '@100mslive/react-sdk';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { useUser } from '../../../contexts/UserContext';
import useEffectOnce from '../../../hooks/useEffectOnce';
import Loading from '../../common/Loading';

import Peer from './Peer';

const StyledPreviewContiner = styled.div`
  & video {
    width: 100%;
  }
`;

const PreviewConference = ({ withUser, authToken, onJoin, onLeave }) => {
  const { user } = useUser();
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const peer = peers.find((p) => p.isLocal);

  console.log('peers: ', peers);

  useEffectOnce(() => {
    (async () => {
      await hmsActions.preview({
        userName: `${user.userFirstName} ${user.userLastName}`,
        authToken,
      });
    })();
  });

  const renderNames = () => {
    const names = peers.filter((p) => !p.isLocal).map((p) => p.name);
    if (names.length === 0) {
      return <p>No one else is on the call.</p>;
    }

    return <p>{names.join(', ')}</p>;
  };

  if (!peer) {
    return <Loading msg="Setting up your video stream..." />;
  }

  return (
    <StyledPreviewContiner>
      <Row>
        <Col xs={12} sm={6} md={3}>
          <Peer peer={peer} />
        </Col>
        <Col>
          <h3>In The Call</h3>
          {renderNames()}
          <Button variant="primary" className="me-2" onClick={() => onJoin()}>
            Join Call
          </Button>
          <Button variant="outline-primary" onClick={() => onLeave()}>
            Leave
          </Button>
        </Col>
      </Row>
    </StyledPreviewContiner>
  );
};

export default PreviewConference;
