import React, { useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../contexts/UserContext';
import { useForm } from '../../hooks/useForm';
import { useAuthLogin } from '../../services/auth';
import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';

const Login = () => {
  const { user, token, verifyId, setVerifyId } = useUser();
  const { form, onChange } = useForm({ email: '' });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('location: ', location);

    if (user && token) {
      navigate('/dashboard', { state: location.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verifyId) {
      navigate('/verify', { state: location.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyId]);

  const callApi = useAuthLogin(
    (res) => {
      setVerifyId(res.userId);
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    callApi.mutate(form.email);
  };

  return (
    <PublicLayout>
      <Row>
        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
          <form onSubmit={onSubmit}>
            <Panel className="pb-3 mb-3">
              <h3 className="text-center">Returning Users</h3>
              <p className="text-center">
                Already have a claim in process with us? Please enter your email
                below to continue.
              </p>
            </Panel>
            <Panel className="pb-3 mb-3">
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={form.email}
                  onChange={onChange}
                  placeholder="your@email.com"
                  required
                />
              </Form.Group>
            </Panel>
            <Panel className="pb-3 mb-3">
              <Row>
                <Col>
                  <Button type="submit" variant="primary">
                    <FontAwesomeIcon
                      icon={callApi.isLoading ? faSpinner : faLock}
                      spin={callApi.isLoading}
                    />{' '}
                    Sign In
                  </Button>
                </Col>
                <Col className="text-end">
                  <Link to="/register">Want to get started?</Link>
                </Col>
              </Row>
            </Panel>
          </form>
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default Login;
