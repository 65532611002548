import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

import QuestionnaireResults from '../../questionnaire/QuestionnaireResults';

const ListStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
`;

const DisplayStyled = styled.div`
  margin-bottom: 0.5rem;

  & > label {
    display: block;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
  }

  & > span {
    font-weight: 400;
  }
`;

const ClaimDetailsTab = ({ claim }) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <h4>Existing Claims</h4>
          <ListStyled>
            {claim.claimRatingsBreakdown.map((c, cIdx) => {
              return (
                <DisplayStyled key={cIdx}>
                  <label>{c.label}</label>
                  <span>{c.rating}%</span>
                </DisplayStyled>
              );
            })}
          </ListStyled>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Questionnaire</h4>
          <QuestionnaireResults
            claimId={claim.claimId}
            answers={claim.questionnaire}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimDetailsTab;
