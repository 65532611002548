import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { getUrl } from '../lib/serviceHelpers';

export const useFileUpload = (claimId, onSuccess) => {
  const [fileId, setFileId] = useState(null);
  const [file, setFile] = useState(null);
  const [folder, setFolder] = useState(null);
  const [busy, setBusy] = useState(false);

  const addFileToClaim = async (fileKey) => {
    const payload = {
      fileName: file.name,
      fileLocation: fileKey,
    };

    if (fileId) {
      payload.fileId = fileId;
    }

    axios
      .post(`/claims/${claimId}/files`, payload)
      .then((res) => {
        if (typeof onSuccess === 'function') {
          onSuccess(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setBusy(false);
        setFileId(null);
        setFolder(null);
      });
  };

  const uploadToS3 = async (s3Url, fileKey) => {
    axios({
      url: s3Url,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': file.type,
      },
    })
      .then((res) => {
        addFileToClaim(fileKey);
      })
      .catch((err) => {
        setBusy(false);
        setFileId(null);
        setFolder(null);
        toast.error(err.message);
      });
  };

  const startUpload = (newFile, newFileId = null, newFolder = null) => {
    setBusy(true);
    setFileId(newFileId);
    setFolder(newFolder);
    setFile(newFile);
  };

  useEffect(() => {
    if (!file) {
      return;
    }

    axios
      .post(getUrl(`/claims/${claimId}/files/presigned-url`), {
        fileName: file.name,
        contentType: file.type,
        action: 'putObject',
        folder,
      })
      .then((res) => {
        uploadToS3(res.data.url, res.data.key);
      })
      .catch((err) => {
        setBusy(false);
        setFileId(null);
        setFolder(null);
        toast.error(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return { busy, file, startUpload };
};
