import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHMSStore, selectPeers } from '@100mslive/react-sdk';

import Peer from './Peer';

const Users = () => {
  const peers = useHMSStore(selectPeers);

  return (
    <div>
      <h2>In Call</h2>

      <div>
        <Row>
          {peers
            .sort((a, b) => (a.isLocal === b.isLocal ? 0 : a.isLocal ? 1 : -1))
            .map((peer) => (
              <Col key={peer.id}>
                <Peer peer={peer} />
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default Users;
