import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { useForm } from '../../hooks/useForm';
import { useFetchPartners, useDeletePartner } from '../../services/partners';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';

import PartnerModal from './modals/PartnerModal';

const Partners = () => {
  const { form, onChange } = useForm({ search: '' });
  const [filters, setFilters] = useState({
    page: 1,
    search: undefined,
    sortBy: 'partnerName',
    sortDir: 'ASC',
  });
  const [partnerModal, setPartnerModal] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchPartners(filters);

  const deletePartner = useDeletePartner(
    () => {
      toast.success('The partner has been successfully removed.');
      queryClient.invalidateQueries('partners');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setFilters({ ...filters, search: form.search });
  };

  return (
    <PrivateLayout>
      <Row>
        <Col>
          <Panel>
            <Row>
              <Col>
                <h2>Partners</h2>
              </Col>
              <Col xs={6} sm={3} className="text-end">
                <Button
                  variant="outline-primary"
                  onClick={() => setPartnerModal(true)}
                >
                  <FontAwesomeIcon icon={faUser} /> Create Partner
                </Button>
              </Col>
              <Col xs={12} sm={4} className="text-end">
                <form onSubmit={onSubmit}>
                  <Form.Control
                    name="search"
                    value={form.search}
                    type="search"
                    placeholder="Search..."
                    onChange={onChange}
                  ></Form.Control>
                </form>
              </Col>
            </Row>
            <br />
            <DataTable
              columns={[
                {
                  header: 'Name',
                  key: 'partnerName',
                  sortable: true,
                },
                {
                  header: 'Discount %',
                  key: 'partnerDiscount',
                  sortable: true,
                },
                {
                  header: 'Referral Url',
                  key: 'partnerReferralUrl',
                  sortable: true,
                },
                {
                  header: 'Assign Referrals To',
                  key: 'partnerAssignReferralsToName',
                  sortable: true,
                },
              ]}
              busy={isLoading}
              actions={[
                {
                  label: 'Edit Partner',
                  onClick: (row) => setPartnerModal(row),
                },
                {
                  divider: true,
                },
                {
                  label: 'Delete Partner',
                  onClick: (row) => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete/deactivate this partner?'
                      )
                    ) {
                      deletePartner.mutate(row.partnerId);
                    }
                  },
                },
              ]}
              data={data ? data.rows : []}
              defaultSort={`${filters.sortBy}:${filters.sortDir}`}
              onSortChange={(newSortBy, newSortDir) => {
                setFilters({
                  ...filters,
                  page: 1,
                  sortBy: newSortBy,
                  sortDir: newSortDir,
                });
              }}
            />

            {data && data.total > 0 ? (
              <Pagination
                page={filters.page}
                totalRows={data.total}
                rowsPerPage={50}
                onBack={() =>
                  setFilters({ ...filters, page: filters.page - 1 })
                }
                onForward={() =>
                  setFilters({ ...filters, page: filters.page + 1 })
                }
              />
            ) : null}
          </Panel>
        </Col>
      </Row>
      <PartnerModal
        show={partnerModal !== false}
        toggle={() => {
          setPartnerModal(false);
        }}
        onSuccess={() => queryClient.invalidateQueries('partners')}
        partner={partnerModal}
      />
    </PrivateLayout>
  );
};

export default Partners;
