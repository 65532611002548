import axios from 'axios';
import { noop } from '../lib/serviceHelpers';
import { useMutation } from '@tanstack/react-query';

export const useCreateLead = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/leads`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateLead = (callback, opts = {}) => {
  return useMutation(
    async ({ leadId, payload }) => {
      const res = await axios.put(`/leads/${leadId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteLead = (callback, opts = {}) => {
  return useMutation(
    async (leadId) => {
      const res = await axios.delete(`/leads/${leadId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
