import React from 'react';
import { Alert, Accordion } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import nl2br from 'react-nl2br';
import moment from 'moment-timezone';

const ClaimTranscripts = ({ claim, transcripts }) => {
  const renderList = () => {
    if (!transcripts || transcripts.length === 0) {
      return (
        <Alert variant="info">
          This claim does not have any video transcripts available. AI review
          content will be available shortly after completing a video call.
        </Alert>
      );
    }

    return (
      <Accordion defaultActiveKey={0}>
        {transcripts.map((transcript, idx) => {
          const dt = moment(transcript.meetingDatetime).format(
            'MM/DD/YYYY h:mm A'
          );

          return Object.keys(transcript.transcriptContent).map(
            (key, keyIdx) => {
              const content = transcript.transcriptContent[key];

              return (
                <Accordion.Item
                  eventKey={idx + keyIdx}
                  key={`${idx}:${keyIdx}`}
                >
                  <Accordion.Header>
                    {dt} / {key.toUpperCase()}
                  </Accordion.Header>
                  <Accordion.Body>
                    {/:markdown$/.test(key) ? (
                      <ReactMarkdown>{content}</ReactMarkdown>
                    ) : (
                      <div>{nl2br(content)}</div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
          );
        })}
      </Accordion>
    );
  };

  return <div>{renderList()}</div>;
};

export default ClaimTranscripts;
