import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 1rem;

  & span {
    display: block;
    padding-top: 2px;
    font-weight: 500;
  }

  & .btn {
    padding: 2px 4px;
    margin-left: 0.5rem;
  }
`;

const Pagination = ({ page, totalRows, rowsPerPage, onBack, onForward }) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <WrapperStyled>
      <div>
        <span>
          Page: {page} of {totalPages}
        </span>
      </div>
      <div>
        <Button
          variant="light"
          size="sm"
          onClick={() => onBack()}
          disabled={page === 1}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </div>
      <div>
        <Button
          variant="light"
          size="sm"
          onClick={() => onForward()}
          disabled={totalPages <= page}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </WrapperStyled>
  );
};

export default Pagination;
