import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';

import Claim from './Claim';

const ClaimDetails = () => {
  const { claimId } = useParams();
  const navigate = useNavigate();

  return (
    <PrivateLayout>
      <Panel style={{ minHeight: '50vh' }}>
        <Claim claimId={claimId} onBack={() => navigate('/dashboard')} />
      </Panel>
    </PrivateLayout>
  );
};

export default ClaimDetails;
