import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useUser } from '../../contexts/UserContext';
import { useForm } from '../../hooks/useForm';
import { useAuthVerify } from '../../services/auth';
import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';

const Verify = () => {
  const { user, token, verifyId, signIn, signOut } = useUser();
  const { form, onChange } = useForm({ code: '' });
  const [redirect, setRedirect] = useState('/dashboard');
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!verifyId) {
      navigate('/sign-in');
    }

    const redirectTo = location.state?.from || '/dashboard';

    if (redirectTo) {
      setRedirect(redirectTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && token) {
      navigate(redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token]);

  const callApi = useAuthVerify(
    (res) => {
      signIn(res, () => {
        queryClient.invalidateQueries();
      });
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    callApi.mutate({ verifyId, code: form.code });
  };

  return (
    <PublicLayout>
      <Row>
        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
          <form onSubmit={onSubmit}>
            <Panel className="mb-3 pb-3 text-center">
              <h3>Verification</h3>
              <p>
                We just sent a one-time use code to the email address provided.
                Please enter the code below to continue.
              </p>
            </Panel>
            <Panel className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                  name="code"
                  value={form.code}
                  onChange={onChange}
                  required
                  autoComplete="off"
                />
              </Form.Group>
            </Panel>
            <Panel className="mb-3 pb-3">
              <Row>
                <Col>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={callApi.isLoading}
                  >
                    Verify
                  </Button>
                </Col>
                <Col className="text-end">
                  <Button
                    variant="link"
                    onClick={() => {
                      signOut();
                      navigate('/sign-in');
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Panel>
          </form>
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default Verify;
