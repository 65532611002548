import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const RoundButton = styled(Button)`
  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #dee2e6;
  color: #445768;
`;

export const Label = styled.label`
  font-weight: 300;
`;

export const Value = styled.p`
  font-weight: 700;
`;
