import React, { useState } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleMinus,
} from '@fortawesome/free-solid-svg-icons';

import { useFetchVaForms } from '../../services/vaForms';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import Loading from '../common/Loading';
import Pagination from '../common/Pagination';

const VaForms = () => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
    search: null,
  });

  const { isLoading, data } = useFetchVaForms(filters);

  const onSubmitSearch = (e) => {
    e.preventDefault();
    setFilters({ page: 1, search });
  };

  const renderList = () => {
    if (isLoading) {
      return <Loading />;
    }

    return data.rows.map((row, rowIdx) => {
      return (
        <tr key={rowIdx}>
          <td>{row.formName}</td>
          <td>
            <span
              className={row.formFieldsMap ? 'text-success' : 'text-danger'}
            >
              <FontAwesomeIcon
                icon={row.formFieldsMap ? faCheckCircle : faCircleMinus}
              />
            </span>
          </td>
          <td>{row.formActive ? 'Active' : 'Inactive'}</td>
          <td className="text-end">
            <Link
              to={`/admin/tools/va-forms/mapper/${row.formId}`}
              className="btn btn-primary btn-sm"
            >
              Map
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <PrivateLayout>
      <Panel className="pb-3 mb-3">
        <Row>
          <Col xs={12} sm={6} md={7}>
            <h2 className="mb-3">Tools: VA Forms</h2>
          </Col>
          <Col xs={12} sm={6} md={5}>
            <form onSubmit={onSubmitSearch}>
              <Form.Control
                type="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            </form>
          </Col>
        </Row>
        <hr />
        <Table className="mb-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Has Fields Mapped</th>
              <th>Active</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{renderList()}</tbody>
        </Table>

        {data && data.total > 0 ? (
          <Pagination
            page={filters.page}
            totalRows={data.total}
            rowsPerPage={50}
            onBack={() => setFilters({ ...filters, page: filters.page - 1 })}
            onForward={() => setFilters({ ...filters, page: filters.page + 1 })}
          />
        ) : null}
      </Panel>
    </PrivateLayout>
  );
};

export default VaForms;
