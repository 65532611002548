import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop } from '../lib/serviceHelpers';

export const useFetchAuthUri = (callback, opts = {}) => {
  return useMutation(
    async () => {
      const res = await axios.get(`/quickbooks/auth-uri`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useConvertAuthCode = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/quickbooks/convert-auth-code`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
