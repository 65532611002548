import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop, getUrl } from '../lib/serviceHelpers';

export const useFetchUsers = (
  page,
  search,
  sortBy,
  sortDir,
  userType = undefined
) => {
  const cacheKey = userType || 'users';

  return useQuery(
    [cacheKey, { page, search, sortBy, sortDir, userType }],
    async () => {
      const url = getUrl('/users', {
        page: page || 1,
        search,
        sortBy,
        sortDir,
        userType,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useFetchUserExpanded = (userId) => {
  const cacheKey = `user-${userId}`;

  return useQuery(
    cacheKey,
    async () => {
      const url = getUrl(`/users/${userId}`, {
        expanded: true,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useCreateUser = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/users`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateUser = (callback, opts = {}) => {
  return useMutation(
    async ({ userId, payload }) => {
      const res = await axios.put(`/users/${userId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteUser = (callback, opts = {}) => {
  return useMutation(
    async (userId) => {
      const res = await axios.delete(`/users/${userId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useFetchAdmins = (page, search, sortBy, sortDir) => {
  return useQuery(
    ['admins', { page, search, sortBy, sortDir }],
    async () => {
      const url = getUrl('/users', {
        userType: 'admin',
        page: page || 1,
        search,
        sortBy,
        sortDir,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useCreateAdmin = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/users/admin`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateAdmin = (callback, opts = {}) => {
  return useMutation(
    async ({ userId, payload }) => {
      const res = await axios.put(`/users/admin/${userId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useCreateProvider = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/users/provider`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateProvider = (callback, opts = {}) => {
  return useMutation(
    async ({ userId, payload }) => {
      const res = await axios.put(`/users/provider/${userId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
