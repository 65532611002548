import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PublicLayout from '../layouts/PublicLayout';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/sign-in');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicLayout>
      <p>Redirecting...</p>
    </PublicLayout>
  );
};

export default Home;
