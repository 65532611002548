import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop, getUrl } from '../lib/serviceHelpers';

export const useFetchVaForms = (params = {}, opts = {}) => {
  return useQuery(
    ['va-forms', { params }],
    async () => {
      const url = getUrl('/forms', params);
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useFetchVaForm = (formId, opts = {}) => {
  return useQuery(
    ['va-forms', formId],
    async () => {
      const url = `/forms/${formId}`;
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useUpdateVaForm = (callback, opts = {}) => {
  return useMutation(
    async ({ formId, updates }) => {
      const res = await axios.put(`/forms/${formId}`, {
        ...updates,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
