import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const noop = () => {};

export const useAuthLogin = (callback, opts = {}) => {
  return useMutation(
    async (email) => {
      const res = await axios.post('/auth/login', {
        email,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useAuthVerify = (callback, opts = {}) => {
  return useMutation(
    async ({ verifyId, code }) => {
      const res = await axios.post('/auth/verify', {
        userId: verifyId,
        code: code,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useAuthRegister = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post('/auth/register', {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
