import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPartnerUrl } from '../../services/partners';
import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';
import Loading from '../common/Loading';

const PartnerLink = () => {
  const { partnerReferralUrl } = useParams();
  const navigate = useNavigate();
  const checkLink = useCheckPartnerUrl((res) => {
    if (res && res.partnerId) {
      navigate('/register', {
        state: {
          partner: res,
        },
      });
    } else {
      navigate('/register');
    }
  });

  useEffect(() => {
    checkLink.mutate(partnerReferralUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicLayout>
      <Panel>
        <Loading msg="Checking partner link..." />
      </Panel>
    </PublicLayout>
  );
};

export default PartnerLink;
