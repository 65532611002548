import { useState } from 'react';
import set from 'lodash.set';

export const useForm = (defaultValues) => {
  const [form, setForm] = useState({ ...defaultValues });
  const [hasChanged, setHasChanged] = useState(false);

  const onChange = (event) => {
    setHasChanged(true);
    const changes = { ...form };
    set(changes, event.target.name, event.target.value);
    setForm(changes);
  };

  const resetChange = () => {
    setHasChanged(false);
  };

  const setValues = (data, merge = false) => {
    setHasChanged(true);
    setForm(merge ? { ...form, ...data } : data);
  };

  return { form, hasChanged, onChange, resetChange, setValues };
};
