import styled from 'styled-components';

const Panel = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fdfdfd;
  padding: 1rem;
  padding-bottom: 0;
`;

export default Panel;
