import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import UserSelect from '../../common/UserSelect';

const noop = () => alert('onSelect Not Implemented');

const UserPickerModal = ({ show, toggle, busy, settings = {} }) => {
  const [selection, setSelection] = useState(null);
  const label = settings.label || 'Select User';
  const userType = settings.userType || 'admin';
  const onSelect = settings.onSelect || noop;
  const value = settings.value || null;
  const isMulti = settings.isMulti || false;

  useEffect(() => {
    setSelection(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSelect(selection);
  };

  return (
    <Modal show={show} onHide={() => toggle}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>{label}</Form.Label>
            <UserSelect
              userType={userType}
              onChange={(val) => setSelection(val)}
              showDefaultOptions={true}
              value={selection}
              isMulti={isMulti}
            />
          </Form.Group>
          <hr />
          <div>
            <Button type="submit" variant="primary" disabled={busy}>
              <FontAwesomeIcon
                icon={busy ? faSpinner : faCheck}
                spin={busy}
                fixedWidth
              />{' '}
              {busy ? 'Saving...' : 'Save'}
            </Button>
            <Button variant="link" onClick={() => toggle()}>
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserPickerModal;
