import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { useForm } from '../../hooks/useForm';
import { useFetchAdmins, useDeleteUser } from '../../services/users';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';
import { ucwords } from '../../lib/strings';

import AdminModal from './modals/AdminModal';

const Admins = () => {
  const { form, onChange } = useForm({ search: '' });
  const [filters, setFilters] = useState({
    page: 1,
    search: undefined,
    sortBy: 'userLastName',
    sortDir: 'ASC',
  });
  const [adminModal, setAdminModal] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchAdmins(
    filters.page,
    filters.search,
    filters.sortBy,
    filters.sortDir
  );

  const deleteUser = useDeleteUser(
    () => {
      toast.success('The admin has been successfully removed.');
      queryClient.invalidateQueries('admins');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setFilters({ ...filters, search: form.search });
  };

  return (
    <PrivateLayout>
      <Row>
        <Col>
          <Panel>
            <Row>
              <Col>
                <h2>Admins</h2>
              </Col>
              <Col xs={6} sm={3} className="text-end">
                <Button
                  variant="outline-primary"
                  onClick={() => setAdminModal(true)}
                >
                  <FontAwesomeIcon icon={faUser} /> Create Admin
                </Button>
              </Col>
              <Col xs={12} sm={4} className="text-end">
                <form onSubmit={onSubmit}>
                  <Form.Control
                    name="search"
                    value={form.search}
                    type="search"
                    placeholder="Search..."
                    onChange={onChange}
                  ></Form.Control>
                </form>
              </Col>
            </Row>
            <br />
            <DataTable
              columns={[
                {
                  header: 'Last Name',
                  key: 'userLastName',
                  sortable: true,
                },
                {
                  header: 'First Name',
                  key: 'userFirstName',
                  sortable: true,
                },
                {
                  header: 'Email',
                  key: 'userEmail',
                  sortable: true,
                },
                {
                  header: 'Role',
                  key: 'adminRole',
                  sortable: true,
                  render: (row) => ucwords(row.adminRole),
                },
              ]}
              busy={isLoading}
              actions={[
                {
                  label: 'Edit Admin',
                  onClick: (row) => setAdminModal(row),
                },
                {
                  divider: true,
                },
                {
                  label: 'Delete Admin',
                  onClick: (row) => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete/deactivate this user?'
                      )
                    ) {
                      deleteUser.mutate(row.userId);
                    }
                  },
                },
              ]}
              data={data ? data.rows : []}
              defaultSort={`${filters.sortBy}:${filters.sortDir}`}
              onSortChange={(newSortBy, newSortDir) => {
                setFilters({
                  ...filters,
                  page: 1,
                  sortBy: newSortBy,
                  sortDir: newSortDir,
                });
              }}
            />

            {data && data.total > 0 ? (
              <Pagination
                page={filters.page}
                totalRows={data.total}
                rowsPerPage={50}
                onBack={() =>
                  setFilters({ ...filters, page: filters.page - 1 })
                }
                onForward={() =>
                  setFilters({ ...filters, page: filters.page + 1 })
                }
              />
            ) : null}
          </Panel>
        </Col>
      </Row>
      <AdminModal
        show={adminModal !== false}
        toggle={() => {
          setAdminModal(false);
        }}
        onSuccess={() => queryClient.invalidateQueries('admins')}
        user={adminModal}
      />
    </PrivateLayout>
  );
};

export default Admins;
