import React, { useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { useFetchDashboard } from '../../../../services/dashboard';
import Panel from '../../../common/Panel';
import Loading from '../../../common/Loading';
import MeetingInviteModal from '../../../calendar/components/MeetingInviteModal';

const StyledRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;

  & > [class*='col-'] {
    flex-grow: 1;
  }
`;

const ActivityBar = () => {
  const [meetingInvite, setMeetingInvite] = useState(null);
  const queryClient = useQueryClient();

  const { isLoading: isLoadingDashboard, data: dashboard } =
    useFetchDashboard();

  const renderInvites = () => {
    if (isLoadingDashboard) {
      return <Loading msg="" />;
    } else if (dashboard.meetingInvites.length === 0) {
      return (
        <p>You do not have any meetings in the next 60 days. Send one now.</p>
      );
    }

    return (
      <Table>
        <tbody>
          {dashboard.meetingInvites.map((invite, inviteIdx) => {
            const diff = moment(invite.meetingInviteDate).diff(moment());
            let className = diff < 0 ? 'text-muted' : '';

            if (diff > 0 && diff < 10 * 60 * 1000) {
              className = 'text-danger';
            }

            return (
              <tr key={inviteIdx}>
                <td>
                  <p className="m-0 mt-1">
                    {invite.clientUserFirstName} {invite.clientUserLastName}
                  </p>
                </td>
                <td>
                  <p className={`m-0 mt-1 ${className}`}>
                    {moment(invite.meetingInviteDate).format('MMM Do @ h:mm A')}
                  </p>
                </td>
                <td className="text-end">
                  <Link
                    to={`/meeting/${invite.meetingInviteId}`}
                    className="btn btn-primary btn-sm"
                  >
                    Join
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderActivity = () => {
    if (isLoadingDashboard) {
      return <Loading msg="" />;
    } else if (dashboard.activity.length === 0) {
      return <p>No new activity.</p>;
    }

    return (
      <Table>
        <tbody>
          {dashboard.activity.map((row, rowIdx) => {
            let label = '';

            switch (row.type) {
              case 'claim:created':
                label = 'created a new claim';
                break;
              case 'claim:updated':
                label = 'updated their claim';
                break;
              case 'file:created':
                label = `uploaded new file ${row.fileName}`;
                break;
              case 'file:updated':
                label = `updated a new version of ${row.fileName}`;
                break;
              default:
                break;
            }

            return (
              <tr key={rowIdx}>
                <td>
                  <strong>{moment(row.date).format('MM/DD/YYYY')}</strong>
                </td>
                <td>
                  <Link
                    to={`/admin/claim/${row.claimId}`}
                  >{`${row.userFirstName} ${row.userLastName} ${label}.`}</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <StyledRow className="mb-3">
        <Col xs={12} sm={6}>
          <Panel className="mb-3 pb-3 h-100">
            <Link to="/calendar" className="float-end">
              Your Calendar
            </Link>
            <h2>Upcoming Calls</h2>
            {renderInvites()}
          </Panel>
        </Col>
        <Col xs={12} sm={6}>
          <Panel className="mb-3 pb-3 h-100">
            <h2>Activity</h2>
            {renderActivity()}
          </Panel>
        </Col>
      </StyledRow>
      <MeetingInviteModal
        show={meetingInvite !== null}
        toggle={() => setMeetingInvite(null)}
        onSuccess={() => {
          queryClient.invalidateQueries('calendar');
        }}
        meetingInvite={meetingInvite}
      />
    </>
  );
};

export default ActivityBar;
