import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckReferralLink } from '../../services/invitations';
import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';
import Loading from '../common/Loading';

const ReferralLink = () => {
  const { referralId } = useParams();
  const navigate = useNavigate();
  const checkLink = useCheckReferralLink((res) => {
    if (res.rows && res.rows.length > 0) {
      navigate('/register', {
        state: {
          userReferredBy: {
            value: res.rows[0].userId,
            label: `${res.rows[0].userLastName}, ${res.rows[0].userFirstName}`,
          },
        },
      });
    } else {
      navigate('/register');
    }
  });

  useEffect(() => {
    checkLink.mutate(referralId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicLayout>
      <Panel>
        <Loading msg="Checking referral link..." />
      </Panel>
    </PublicLayout>
  );
};

export default ReferralLink;
