import React from 'react';

import { useUser } from '../../contexts/UserContext';
import PrivateLayout from '../layouts/PrivateLayout';
import SalesDashboard from './admin/SalesDashboard';
import ClientDashboard from './client/ClientDashboard';

const Dashboard = () => {
  const { user } = useUser();

  if (user.userType === 'client') {
    return (
      <PrivateLayout>
        <ClientDashboard />
      </PrivateLayout>
    );
  }

  return (
    <PrivateLayout>
      <SalesDashboard />
    </PrivateLayout>
  );
};

export default Dashboard;
