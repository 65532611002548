import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useCreateProvider, useUpdateProvider } from '../../../services/users';
import { useForm } from '../../../hooks/useForm';
import ClaimSelect from '../../common/ClaimSelect';
import TimezoneSelect from '../../common/TimezoneSelect';
import StateSelect from '../../common/StateSelect';

const DEFAULTS = {
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userType: 'provider',
  userDateOfBirth: '1950-01-01',
  userTimezone: 'America/Chicago',
  userState: '',
  claims: [],
};

const ProviderModal = ({ show, toggle, user, onSuccess }) => {
  const { form, onChange, setValues } = useForm(DEFAULTS);

  useEffect(() => {
    if (user) {
      const claims = user._claims || [];

      setValues({
        ...DEFAULTS,
        ...user,
        claims: claims.map((c) => {
          return {
            label: `${c.claimUserFirstName} ${c.claimUserLastName}`,
            value: c.claimId,
          };
        }),
      });
    } else {
      setValues(DEFAULTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const sendInvite = useCreateProvider(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Provider created and invite sent successfully.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const updateUser = useUpdateProvider(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Provider successfully updated.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      userEmail: form.userEmail,
      userFirstName: form.userFirstName,
      userLastName: form.userLastName,
      userType: form.userType,
      userDateOfBirth: form.userDateOfBirth,
      userTimezone: form.userTimezone,
      userState: form.userState || null,
      claims: form.claims.map((v) => v.value),
    };

    if (form.userId) {
      updateUser.mutate({
        userId: form.userId,
        payload,
      });
    } else {
      sendInvite.mutate(payload);
    }
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Create Provider</h3>
        <form id="invite-form" onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="userEmail"
              onChange={onChange}
              value={form.userEmail}
              required
              placeholder="user@email.com"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="userFirstName"
              onChange={onChange}
              value={form.userFirstName}
              required
              placeholder="First"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="userLastName"
              onChange={onChange}
              value={form.userLastName}
              required
              placeholder="Last"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <StateSelect
              name="userState"
              onChange={onChange}
              value={form.userState}
              required={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Timezone</Form.Label>
            <TimezoneSelect
              name="userTimezone"
              onChange={onChange}
              value={form.userTimezone}
              require={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Claim Access</Form.Label>
            <ClaimSelect
              value={form.claims}
              onChange={(vals) =>
                onChange({ target: { name: 'claims', value: vals } })
              }
              isMulti={true}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          form="invite-form"
          disabled={sendInvite.isLoading}
        >
          <FontAwesomeIcon
            icon={sendInvite.isLoading ? faSpinner : faCheck}
            spin={sendInvite.isLoading}
          />{' '}
          Save
        </Button>{' '}
        <Button variant="link" onClick={() => toggle()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderModal;
