import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faFile,
  faFilePdf,
  faFileWord,
  faFileImage,
  faFileVideo,
} from '@fortawesome/free-regular-svg-icons';

import CustomDropdown from '../../common/CustomDropdown';

const GridItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  text-align: center;

  & > div {
    font-size: 3rem;
  }
`;

const splitFilename = (str) => {
  const matches = str.match(/.{1,22}/g);
  return matches.map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

const FolderItem = ({ folder, onClick }) => {
  return (
    <GridItemStyled onClick={onClick}>
      <div>
        <FontAwesomeIcon icon={faFolder} />
      </div>
      <span>{folder.label}</span>
    </GridItemStyled>
  );
};

const FileItem = ({ file, onClick }) => {
  const getFileIcon = () => {
    const matches = /\.(.+)$/.exec(file.fileName);

    switch (matches[1].toLowerCase()) {
      case 'pdf':
        return faFilePdf;

      case 'doc':
      case 'docx':
        return faFileWord;

      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'tiff':
      case 'gif':
        return faFileImage;

      case 'mp4':
      case 'ogg':
      case 'mpeg':
        return faFileVideo;

      default:
        return faFile;
    }
  };

  return (
    <GridItemStyled>
      <CustomDropdown
        variant="link"
        drop="bottom"
        className="w-100"
        menuItems={[
          /* {
            label: 'Generate File',
            onClick: () => onClick(file, 'file:download'),
            isDisabled: () => false, // file.fileVersion === 0,
          }, */
          {
            label: 'Open File',
            onClick: () => onClick(file, 'file:open'),
            isDisabled: () => file.fileVersion === 0,
          },
          {
            label: 'Upload New Version',
            onClick: () => onClick(file, 'file:replace'),
            isDisabled: () => file.fileVersion === 0,
          },
          {
            label: 'Delete File',
            onClick: () => onClick(file, 'file:delete'),
            isDisabled: () => file.fileVersion === 0,
          },
        ]}
        label={
          <div>
            <div id={`file-${file.fileId}`} style={{ fontSize: '3rem' }}>
              <FontAwesomeIcon icon={getFileIcon()} />
            </div>
            <p className="p-0">{splitFilename(file.fileName)}</p>
          </div>
        }
      />
    </GridItemStyled>
  );
};

const GridView = ({ folders, files, onFileClick, onFolderClick }) => {
  return (
    <div
      className="d-flex justify-content-start align-items-start"
      style={{ gap: '2rem', flexWrap: 'wrap' }}
    >
      {folders.map((folder, folderIdx) => {
        return (
          <FolderItem
            key={folderIdx}
            folder={folder}
            onClick={() => onFolderClick(folder)}
          />
        );
      })}
      {files.map((file, fileIdx) => {
        return <FileItem key={fileIdx} file={file} onClick={onFileClick} />;
      })}
    </div>
  );

  // return (
  //   <div>
  //     <Row>
  //       {folders.map((folder, folderIdx) => {
  //         return (
  //           <Col key={folderIdx} xs={6} sm={4} md={3} className="mb-3">
  //             <FolderItem
  //               folder={folder}
  //               onClick={() => onFolderClick(folder)}
  //             />
  //           </Col>
  //         );
  //       })}
  //       {files.map((file, fileIdx) => {
  //         return (
  //           <Col key={fileIdx} xs={6} sm={4} md={3} className="mb-3">
  //             <FileItem key={fileIdx} file={file} onClick={onFileClick} />
  //           </Col>
  //         );
  //       })}
  //     </Row>
  //   </div>
  // );
};

export default GridView;
