import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../hooks/useForm';
import { useUpdateLead } from '../../services/leads';
import AdminSelect from '../common/AdminSelect';

const AssignLeadToModal = ({ show, toggle, lead, onSuccess }) => {
  const { form, onChange, setValues } = useForm({ assignTo: null });

  const updateLead = useUpdateLead(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  useEffect(() => {
    if (lead && lead.leadAssignedTo) {
      setValues(
        {
          assignTo: {
            label: `${lead.assignedLastName}, ${lead.assignedFirstName}`,
          },
          value: lead.leadAssignedTo,
        },
        true
      );
    } else {
      setValues({ assignTo: null }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const onSubmit = (e) => {
    e.preventDefault();

    updateLead.mutate({
      leadId: lead.leadId,
      payload: {
        leadAssignedTo: form.assignTo ? form.assignTo.value : null,
      },
    });
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Assign To</Form.Label>
            <AdminSelect
              value={form.assignTo}
              onChange={(val) => {
                console.log(val);
                onChange({ target: { name: 'assignTo', value: val } });
              }}
              showDefaultOptions={true}
            />
          </Form.Group>
          <hr />
          <Button
            variant="success"
            type="submit"
            disabled={updateLead.isLoading}
          >
            <FontAwesomeIcon
              icon={updateLead.isLoading ? faSpinner : faCheck}
              spin={updateLead.isLoading}
            />{' '}
            Assign
          </Button>{' '}
          <Button variant="link" onClick={() => toggle()}>
            Cancel
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AssignLeadToModal;
