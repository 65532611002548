import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop } from '../lib/serviceHelpers';

export const useFetchClaimActivity = (claimId, params = {}) => {
  return useQuery(
    ['activity', claimId, params],
    async () => {
      const res = await axios.get(`/claims/${claimId}/activity`, { params });
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useCreateClaimActivity = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, payload }) => {
      const res = await axios.post(`/claims/${claimId}/activity`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateClaimActivity = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, actId, payload }) => {
      const res = await axios.put(`/claims/${claimId}/activity/${actId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteClaimActivity = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, actId }) => {
      const res = await axios.delete(`/claims/${claimId}/activity/${actId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
