import React from 'react';
import styled from 'styled-components';

import Users from './Users';
import Controls from './Controls';

const InConferenceStyled = styled.div`
  position: relative;
  width: 100%;

  & .control-bar {
    width: 100%;
    text-align: center;
  }

  & video {
    width: 100%;
  }
`;

const InConference = ({ onEndCall }) => {
  return (
    <InConferenceStyled>
      <Users />
      <Controls onEndCall={onEndCall} />
    </InConferenceStyled>
  );
};

export default InConference;
