import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop, getUrl } from '../lib/serviceHelpers';

export const useFetchCalendar = (startDt, endDt, userId, opts = {}) => {
  return useQuery(
    ['calendar', { startDt, endDt, userId }],
    async () => {
      const url = getUrl('/meetings/invites', {
        startDt,
        endDt,
        userId,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useCreateCall = (callback, opts = {}) => {
  return useMutation(
    async (inviteId) => {
      const res = await axios.get(`/meetings/invites/join/${inviteId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpsertMeetingInvite = (callback, opts = {}) => {
  return useMutation(
    async ({ meetingInviteId, payload }) => {
      const res = await axios({
        url: `/meetings/invites${meetingInviteId ? `/${meetingInviteId}` : ''}`,
        method: meetingInviteId ? 'put' : 'post',
        data: { ...payload },
      });

      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteMeetingInvite = (callback, opts = {}) => {
  return useMutation(
    async (meetingInviteId) => {
      const res = await axios({
        url: `/meetings/invites/${meetingInviteId}`,
        method: 'delete',
      });

      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
