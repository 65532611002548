export const AGING_REPORT_HEADERS = {
  "claimId": "Claim ID",
  "claimStatus": "Status",
  "clientName": "Client Name",
  "assignedTo": "Assigned To",
  "claimCreatedOn": "Created On",
  "claimLastTouched": "Last Modified",
  "claimMinValue": "Minimum Value",
  "claimMaxValue": "Maximum Value",
};

export const OUTSTANDING_INVOICES_HEADERS = {
  "claimId": "Claim ID",
  "claimStatus": "Status",
  "clientName": "Client Name",
  "assignedTo": "Assigned To",
  "claimCreatedOn": "Created On",
  "claimLastTouched": "Last Modified",
  "claimMinValue": "Minimum Value",
  "claimMaxValue": "Maximum Value",
};

export const OPEN_REPORT_HEADERS = {
  "claimId": "Claim ID",
  "claimStatus": "Status",
  "clientName": "Client Name",
  "assignedTo": "Assigned To",
  "claimCreatedOn": "Created On",
  "claimLastTouched": "Last Modified",
  "claimMinValue": "Minimum Value",
  "claimMaxValue": "Maximum Value",
};

export const TIMELINE_REPORT_HEADERS = {
  "claimId": "Claim ID",
  "claimStatus": "Status",
  "clientName": "Client Name",
  "assignedTo": "Assigned To",
  "claimCreatedOn": "Created On",
  "claimLastTouched": "Last Modified",
  "claimMinValue": "Minimum Value",
  "claimMaxValue": "Maximum Value",
};