import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop } from '../lib/serviceHelpers';

export const useFetchPartners = (params = {}, opts = {}) => {
  return useQuery(
    ['partners', params],
    async () => {
      const res = await axios.get(`/partners`, { params });
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useFetchPartner = (partnerId, opts = {}) => {
  return useQuery(
    ['partners', partnerId],
    async () => {
      const res = await axios.get(`/partners/${partnerId}`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useCheckPartnerUrl = (callback, opts = {}) => {
  return useMutation(
    async (url) => {
      const res = await axios.post(`/auth/check-url`, {
        url,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useCreatePartner = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/partners`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdatePartner = (callback, opts = {}) => {
  return useMutation(
    async ({ partnerId, payload }) => {
      const res = await axios.put(`/partners/${partnerId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeletePartner = (callback, opts = {}) => {
  return useMutation(
    async (partnerId) => {
      const res = await axios.delete(`/partners/${partnerId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
