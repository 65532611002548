import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';

import { useUser } from '../../../contexts/UserContext';
import Panel from '../../common/Panel';
import Loading from '../../common/Loading';
import Claim from '../../claim/Claim';
import { useFetchCalendar } from '../../../services/calls';

const ClientDashboard = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const { isLoading, data: meetingInvites } = useFetchCalendar(
    moment().startOf('day').format(),
    moment().endOf('day').add(6, 'months').format()
  );

  useEffect(() => {
    if (!user._currentClaimId) {
      navigate('/questionnaire');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCalendar = () => {
    if (isLoading) {
      return <Loading msg="" />;
    } else if (meetingInvites.length === 0) {
      return <p>No meeting set for today. Send one now.</p>;
    }

    return (
      <>
        <h5>Upcoming Meetings</h5>
        <Table striped>
          <tbody>
            {meetingInvites.map((invite, inviteIdx) => {
              const diff = moment(invite.meetingInviteDate).diff(moment());
              let className = diff < 0 ? 'text-muted' : '';

              if (diff > 0 && diff < 10 * 60 * 1000) {
                className = 'text-danger';
              }

              return (
                <tr key={inviteIdx}>
                  <td>
                    <p className="m-0 mt-1">
                      {invite.userFirstName} {invite.userLastName}
                    </p>
                  </td>
                  <td>
                    <p className={`m-0 mt-1 ${className}`}>
                      {moment(invite.meetingInviteDate).format('MM/DD h:mm A')}
                    </p>
                  </td>
                  <td className="text-end">
                    <Link
                      to={`/meeting/${invite.meetingInviteId}`}
                      className="btn btn-outline-primary btn-sm"
                    >
                      Join
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <Panel style={{ minHeight: '50vh' }}>
      {user._currentClaimId ? (
        <Claim
          claimId={user._currentClaimId}
          onBack={null}
          headerContent={renderCalendar()}
        />
      ) : (
        <p>Redirecting...</p>
      )}
    </Panel>
  );
};

export default ClientDashboard;
