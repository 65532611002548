import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Row, Col, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { useUser } from '../../contexts/UserContext';
import { useFetchCalendar } from '../../services/calls';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import AdminSelect from '../common/AdminSelect';
import MeetingInviteModal from './components/MeetingInviteModal';

const CalendarWrapper = styled.div`
  position: relative;
`;

const CalendarLoading = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 999;
  color: #000;
  font-size: 1.25em;
  font-weight: 700;
  padding-top: 30%;
  text-align: center;
`;

const Calendar = () => {
  const { user } = useUser();
  const [dates, setDates] = useState({
    userId: null,
    show: null,
    start: null,
    end: null,
    enabled: false,
  });
  const [filters, setFilters] = useState({
    start: null,
    end: null,
    userId: null,
  });
  const [events, setEvents] = useState([]);
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchCalendar(
    filters.start,
    filters.end,
    filters.userId,
    {
      onSuccess: (res) => {
        const newEvents = {};

        res.forEach((row) => {
          if (!newEvents[row.meetingInviteUserId]) {
            newEvents[row.meetingInviteUserId] = { events: [], color: 'blue' };
          }

          newEvents[row.meetingInviteUserId].events.push({
            id: row.meetingInviteId,
            title: `${row.clientUserFirstName} ${row.clientUserLastName}`,
            date: moment(row.meetingInviteDate).toDate(),
          });
        });

        setEvents(Object.values(newEvents));
      },
      enabled: dates.enabled,
    }
  );

  useEffect(() => {
    setFilters({
      start: dates.start,
      end: dates.end,
      userId: dates.userId ? dates.userId.value : null,
    });
  }, [dates]);

  return (
    <PrivateLayout>
      <Panel className="pb-3 mb-3">
        <Row>
          <Col xs={12} sm={6} md={7}>
            <h2 className="mb-3">Calendar</h2>
          </Col>
          <Col xs={12} sm={3} md={3}>
            {user._admin && user._admin.adminRole === 'admin' ? (
              <AdminSelect
                value={dates.userId}
                onChange={(val) => setDates({ ...dates, userId: val })}
                isMulti={false}
                showDefaultOptions={true}
                includeAllOption
              />
            ) : null}
          </Col>
          <Col xs={12} sm={3} md={2}>
            <Button
              variant="outline-primary"
              className="w-100"
              onClick={() =>
                setDates({ ...dates, show: { meetingInviteId: null } })
              }
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth /> Meeting Invite
            </Button>
          </Col>
        </Row>
        <hr />
        <CalendarWrapper>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              start: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            eventSources={events}
            dateClick={(info) => {
              setDates({
                ...dates,
                show: { meetingInviteDate: moment(info.dateStr).format() },
              });
            }}
            eventClick={(info) => {
              const eventId = info.event.id;
              const meetingInvite = data.find(
                (row) => row.meetingInviteId === eventId
              );

              if (meetingInvite) {
                setDates({ ...dates, show: meetingInvite });
              }
            }}
            datesSet={(dateInfo) => {
              setDates({
                ...dates,
                start: moment(dateInfo.start).format('YYYY-MM-DD'),
                end: moment(dateInfo.end).format('YYYY-MM-DD'),
                enabled: true,
              });
            }}
          />
          {isLoading ? (
            <CalendarLoading>
              <p>Loading...</p>
            </CalendarLoading>
          ) : null}
        </CalendarWrapper>
      </Panel>
      <MeetingInviteModal
        show={dates.show !== null}
        toggle={() => setDates({ ...dates, show: null })}
        onSuccess={() => {
          queryClient.invalidateQueries('calendar');
        }}
        meetingInvite={dates.show}
      />
    </PrivateLayout>
  );
};

export default Calendar;
