import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useCreatePartner, useUpdatePartner } from '../../../services/partners';
import { useForm } from '../../../hooks/useForm';
import AdminSelect from '../../common/AdminSelect';

const DEFAULTS = {
  partnerName: '',
  partnerDiscount: 0,
  partnerReferralUrl: '',
  partnerAssignReferralsTo: null,
};

const PartnerModal = ({ show, toggle, partner, onSuccess }) => {
  const { form, onChange, setValues } = useForm(DEFAULTS);

  useEffect(() => {
    if (partner) {
      setValues({
        ...DEFAULTS,
        ...partner,
        partnerAssignReferralsTo: partner.partnerAssignReferralsTo
          ? {
              label: partner.partnerAssignReferralsToName,
              value: partner.partnerAssignReferralsTo,
            }
          : null,
      });
    } else {
      setValues(DEFAULTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  const createPartner = useCreatePartner(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Partner successfully created.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const updatePartner = useUpdatePartner(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Partner successfully updated.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      partnerName: form.partnerName,
      partnerDiscount: form.partnerDiscount,
      partnerReferralUrl: form.partnerReferralUrl.trim().toLowerCase(),
      partnerDetails: form.partnerDetails,
      partnerAssignReferralsTo: form.partnerAssignReferralsTo
        ? form.partnerAssignReferralsTo.value
        : null,
    };

    console.log('partner: ', partner);

    if (partner && partner.partnerId) {
      updatePartner.mutate({
        partnerId: form.partnerId,
        payload,
      });
    } else {
      createPartner.mutate(payload);
    }
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Create Partner</h3>
        {partner && partner.partnerReferralUrl ? (
          <p>
            <strong>Referral Link:</strong>
            <br />
            {window.location.protocol}//{window.location.host}/partner/
            {partner.partnerReferralUrl}
          </p>
        ) : null}
        <form id="partner-form" onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Partner Name</Form.Label>
            <Form.Control
              name="partnerName"
              onChange={onChange}
              value={form.partnerName}
              required
              placeholder="Partner Name, LLC"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Referral URL</Form.Label>
            <Form.Control
              name="partnerReferralUrl"
              onChange={onChange}
              value={form.partnerReferralUrl}
              required
              placeholder="partner-name"
            />
            <small className="text-muted">
              {window.location.protocol}//{window.location.hostname}/partner/
              {form.partnerReferralUrl
                .trim()
                .toLowerCase()
                .replace(/[^\w-]/g, '-')}
            </small>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Discount Percent</Form.Label>
            <Form.Control
              type="number"
              name="partnerDiscount"
              onChange={onChange}
              value={form.partnerDiscount}
              required
              min="0"
              max="100"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Partner Referrals To</Form.Label>
            <AdminSelect
              value={form.partnerAssignReferralsTo}
              onChange={(val) =>
                onChange({
                  target: { name: 'partnerAssignReferralsTo', value: val },
                })
              }
              isMulti={false}
              showDefaultOptions={true}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              name="partnerDetails"
              onChange={onChange}
              value={form.partnerDetails}
              required
              style={{ minHeight: 200 }}
            />
            <small className="text-muted">
              This will be public to clients that use the referral link for this
              partner.
            </small>
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          form="partner-form"
          disabled={createPartner.isLoading || updatePartner.isLoading}
        >
          <FontAwesomeIcon
            icon={
              createPartner.isLoading || updatePartner.isLoading
                ? faSpinner
                : faCheck
            }
            spin={createPartner.isLoading || updatePartner.isLoading}
          />{' '}
          Save
        </Button>{' '}
        <Button variant="link" onClick={() => toggle()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartnerModal;
