import React from 'react';
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

const LeadDetailsModal = ({ show, toggle, lead }) => {
  if (!lead) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Lead Details</h3>
        <hr />
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <p className="mb-0 fw-bold">
                {lead.leadFirstName} {lead.leadLastName}
              </p>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <p className="mb-0 fw-bold">{lead.leadPhone}</p>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <p className="mb-0 fw-bold">{lead.leadEmail}</p>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Source</Form.Label>
              <p className="mb-0 fw-bold">{lead.leadSource}</p>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Assigned To</Form.Label>
              <p className="mb-0 fw-bold">
                {lead.assignedFirstName} {lead.assignedLastName}
              </p>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Referred By</Form.Label>
              <p className="mb-0 fw-bold">
                {lead.refFirstName} {lead.refLastName}
              </p>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Created On</Form.Label>
              <p className="mb-0 fw-bold">
                {moment(lead.leadCreatedOn).format('MM/DD/YYYY')}
              </p>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Updated On</Form.Label>
              <p className="mb-0 fw-bold">
                {moment(lead.leadUpdatedOn).format('MM/DD/YYYY')}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Button variant="primary" onClick={() => toggle()}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LeadDetailsModal;
