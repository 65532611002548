import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useCreateAdmin, useUpdateAdmin } from '../../../services/users';
import { useForm } from '../../../hooks/useForm';
import AdminSelect from '../../common/AdminSelect';
import TimezoneSelect from '../../common/TimezoneSelect';
import StateSelect from '../../common/StateSelect';

const DEFAULTS = {
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userDateOfBirth: '1950-01-01',
  userTimezone: 'America/Chicago',
  userState: '',
  adminRole: 'director',
  adminSubUsers: [],
  adminRedirectReferralsTo: null,
  adminCommissionRate: 10,
};

const AdminModal = ({ show, toggle, user, onSuccess }) => {
  const { form, onChange, setValues } = useForm(DEFAULTS);

  useEffect(() => {
    if (user) {
      setValues({
        ...DEFAULTS,
        ...user,
        adminRedirectReferralsTo: user.adminRedirectReferralsTo
          ? {
              label: user.redirectToUserName,
              value: user.adminRedirectReferralsTo,
            }
          : null,
      });
    } else {
      setValues(DEFAULTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const sendInvite = useCreateAdmin(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Admin created and invite sent successfully.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const updateAdmin = useUpdateAdmin(
    () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Admin successfully updated.');
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      userEmail: form.userEmail,
      userFirstName: form.userFirstName,
      userLastName: form.userLastName,
      userTimezone: form.userTimezone,
      userState: form.userState || null,
      adminRole: form.adminRole,
      adminSubUsers: form.adminSubUsers,
      adminRedirectReferralsTo: form.adminRedirectReferralsTo,
      adminCommissionRate: form.adminCommissionRate,
    };

    if (payload.adminRole === 'referral' && !payload.adminRedirectReferralsTo) {
      toast.error('You must select an assign to value for referral partners.');
      return;
    } else if (payload.adminRole !== 'referral') {
      payload.adminRedirectReferralsTo = null;
    } else {
      payload.adminRedirectReferralsTo = form.adminRedirectReferralsTo.value;
    }

    if (form.userId) {
      updateAdmin.mutate({
        userId: form.userId,
        payload,
      });
    } else {
      sendInvite.mutate(payload);
    }
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Create Admin</h3>
        {user && user.userId ? (
          <p>
            <strong>Referral Link:</strong>
            <br />
            {window.location.protocol}\/\/{window.location.host}/referral/
            {user.userId}
          </p>
        ) : null}
        <form id="invite-form" onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="userEmail"
              onChange={onChange}
              value={form.userEmail}
              required
              placeholder="user@email.com"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="userFirstName"
              onChange={onChange}
              value={form.userFirstName}
              required
              placeholder="First"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="userLastName"
              onChange={onChange}
              value={form.userLastName}
              required
              placeholder="Last"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Role</Form.Label>
            <Form.Select
              name="adminRole"
              onChange={onChange}
              value={form.adminRole}
            >
              <option value="admin">Admin</option>
              <option value="director">Managing Director</option>
              <option value="referral">Referral Partner</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <StateSelect
              name="userState"
              onChange={onChange}
              value={form.userState}
              required={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Timezone</Form.Label>
            <TimezoneSelect
              name="userTimezone"
              onChange={onChange}
              value={form.userTimezone}
              require={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Commission Rate</Form.Label>
            <Form.Control
              name="adminCommissionRate"
              onChange={onChange}
              value={form.adminCommissionRate}
              required
              type="number"
              placeholder="10"
            />
          </Form.Group>

          {form.adminRole === 'referral' ? (
            <Form.Group className="mb-3">
              <Form.Label>Assign Referrals To</Form.Label>
              <AdminSelect
                value={form.adminRedirectReferralsTo}
                onChange={(val) =>
                  onChange({
                    target: { name: 'adminRedirectReferralsTo', value: val },
                  })
                }
                isMulti={false}
                showDefaultOptions={true}
              />
            </Form.Group>
          ) : null}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          form="invite-form"
          disabled={sendInvite.isLoading}
        >
          <FontAwesomeIcon
            icon={sendInvite.isLoading ? faSpinner : faCheck}
            spin={sendInvite.isLoading}
          />{' '}
          Save
        </Button>{' '}
        <Button variant="link" onClick={() => toggle()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminModal;
