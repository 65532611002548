export const noop = () => {};

export const getUrl = (url, queryParams) => {
  const params = [];

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key]) {
        params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        );
      }
    });
  }

  return params.length > 0 ? `${url}?${params.join('&')}` : url;
};
