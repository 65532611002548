import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileContract,
  faUserTie,
  faGripVertical,
  faTasks,
  faUserMd,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

import { ucwords } from '../../../../lib/strings';
import CustomDropdown from '../../../common/CustomDropdown';

const StyledItem = styled.div`
  width: 100%;
  padding: 0.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  box-shadow: 0px 10px 4px -8px rgba(0, 0, 0, 0.1);
  color: rgba(33, 37, 41, 0.75);

  & a {
    text-decoration: none;
  }

  & strong {
    color: #000;
  }
`;

const StyledDragHandle = styled.div`
  color: #999;
  text-center;
`;

const SalesCard = ({ card, handleProps, className, actions }) => {
  const daysSince = moment().diff(moment(card.claimUpdatedOn), 'days');

  return (
    <StyledItem className={className || ''}>
      <div className="d-flex justify-content-between align-items-start">
        <Link
          to={`/admin/profile/${card.claimId}`}
          className="m-0 p-0 mb-1 fs-5"
        >
          <strong>
            {card.userFirstName} {card.userLastName}
          </strong>
        </Link>
        <StyledDragHandle {...handleProps}>
          <FontAwesomeIcon icon={faGripVertical} />
        </StyledDragHandle>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <OverlayTrigger
          overlay={
            <Tooltip id={`${card.claimId}-value`}>
              {card.assignedToUserId
                ? `Claim assigned to ${card.assignedToUserFirstName} ${card.assignedToUserLastName}`
                : 'Claim is unassigned.'}
            </Tooltip>
          }
        >
          <p
            className={
              card.assignedToUserId ? `text-muted mb-2` : `text-danger mb-2`
            }
          >
            <FontAwesomeIcon icon={faUserTie} />{' '}
            {card.assignedToUserId
              ? `${card.assignedToUserFirstName} ${card.assignedToUserLastName}`
              : 'Unassigned'}
          </p>
        </OverlayTrigger>
        <p className="mb-2">
          <OverlayTrigger
            overlay={
              <Tooltip
                id={`${card.claimId}-value`}
              >{`Contract potential value for ${card.claimCurrentRating}% - 100%`}</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon icon={faFileContract} />{' '}
              {numeral(card._minValue).format('$0,0')} -{' '}
              {numeral(card._maxValue).format('$0,0')}
            </span>
          </OverlayTrigger>
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="mb-0">{ucwords(card.claimStatus)}</p>
        <p className="mb-0">
          <OverlayTrigger
            overlay={
              <Tooltip
                id={`${card.claimId}-update`}
              >{`Last updated ${daysSince} days ago on ${moment(
                card.claimUpdatedOn
              ).format('MM/DD/YYYY')}`}</Tooltip>
            }
          >
            <span className={daysSince >= 14 ? 'text-danger' : ''}>
              {moment(card.claimUpdatedOn).format('MM/DD/YYYY')}
            </span>
          </OverlayTrigger>
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="d-flex justify-content-end align-items-center gap-3">
          <OverlayTrigger
            overlay={
              <Tooltip
                id={`${card.claimId}-tasks`}
              >{`Completed ${card._completedTasks} of ${card._totalTasks} tasks. Current: ${card._nextTask}`}</Tooltip>
            }
          >
            <small>
              <FontAwesomeIcon icon={faTasks} /> {card._completedTasks}/
              {card._totalTasks}
            </small>
          </OverlayTrigger>
          <small className="d-none">
            <FontAwesomeIcon icon={faUserMd} />
          </small>
          <OverlayTrigger
            overlay={
              <Tooltip
                id={`${card.claimId}-alert`}
              >{`Alerts coming soon.`}</Tooltip>
            }
          >
            <small className="">
              <FontAwesomeIcon icon={faBell} />
            </small>
          </OverlayTrigger>
        </div>
        <div>
          <CustomDropdown menuItems={actions} clickData={card} />
        </div>
      </div>
    </StyledItem>
  );
};

export default SalesCard;
