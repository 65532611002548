import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Markdown from 'react-markdown';

const StyledContentArea = styled.div`
  border: 1px solid #ccc;
  padding: 1rem;
  background: #fdfdfd;
  max-height: 90vh;
  overflow: scroll;
  overflow-x: hidden;

  & code {
    background: #000;
    color: #fff;
    font-weight: 700;
  }

  & strong {
    background: yellow;
    font-weight: 500;
  }
`;

const GeneratedContentViewer = ({ show, toggle, addon }) => {
  if (!addon || !addon.claimAddonContent) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => toggle()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Generated Content Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledContentArea>
          <Markdown>{addon.claimAddonContent.content}</Markdown>
        </StyledContentArea>
      </Modal.Body>
    </Modal>
  );
};

export default GeneratedContentViewer;
