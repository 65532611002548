import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../hooks/useForm';
import {
  useFetchInvitations,
  useResendInvite,
} from '../../services/invitations';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';
import dayjs from '../../lib/day';
import { ucwords } from '../../lib/strings';

import SendInviteModal from './modals/SendInviteModal';

const Invitations = () => {
  const { form, onChange } = useForm({ search: '' });
  const [filters, setFilters] = useState({
    page: 1,
    search: undefined,
    sortBy: 'inviteDate',
    sortDir: 'DESC',
  });
  const [showInviteModal, setShowInviteModal] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchInvitations(
    filters.page,
    filters.search,
    filters.sortBy,
    filters.sortDir
  );

  const resend = useResendInvite(
    () => {
      toast.success('The invitation has been successfully resent.');
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setFilters({ ...filters, search: form.search });
  };

  return (
    <PrivateLayout>
      <Row>
        <Col>
          <Panel>
            <Row>
              <Col>
                <h2>Client Invitations</h2>
              </Col>
              <Col xs={6} sm={3} className="text-end">
                <Button
                  variant="outline-primary"
                  onClick={() => setShowInviteModal(true)}
                >
                  <FontAwesomeIcon icon={faPaperPlane} /> Send Invite
                </Button>
              </Col>
              <Col xs={12} sm={4} className="text-end">
                <form onSubmit={onSubmit}>
                  <Form.Control
                    name="search"
                    value={form.search}
                    type="search"
                    placeholder="Search..."
                    onChange={onChange}
                  ></Form.Control>
                </form>
              </Col>
            </Row>
            <br />
            <DataTable
              columns={[
                {
                  header: 'Email',
                  key: 'inviteEmail',
                  sortable: true,
                },
                {
                  header: 'Sent By',
                  key: 'userLastName',
                  sortable: true,
                  render: (row) => `${row.userLastName}, ${row.userFirstName}`,
                },
                {
                  header: 'Sent On',
                  key: 'inviteDate',
                  sortable: true,
                  render: (row) =>
                    dayjs.utc(row.inviteDate).format('MM/DD/YYYY'),
                },
                {
                  header: 'Status',
                  key: 'inviteStatus',
                  sortable: true,
                  render: (row) => ucwords(row.inviteStatus),
                },
              ]}
              busy={isLoading}
              actions={[
                {
                  label: 'Resend Invitation',
                  onClick: (clickData) => {
                    resend.mutate(clickData.inviteId);
                  },
                },
              ]}
              data={data ? data.rows : []}
              defaultSort={`${filters.sortBy}:${filters.sortDir}`}
              onSortChange={(newSortBy, newSortDir) => {
                setFilters({
                  ...filters,
                  page: 1,
                  sortBy: newSortBy,
                  sortDir: newSortDir,
                });
              }}
            />

            {data && data.total > 0 ? (
              <Pagination
                page={filters.page}
                totalRows={data.total}
                rowsPerPage={50}
                onBack={() =>
                  setFilters({ ...filters, page: filters.page - 1 })
                }
                onForward={() =>
                  setFilters({ ...filters, page: filters.page + 1 })
                }
              />
            ) : null}
          </Panel>
        </Col>
      </Row>
      <SendInviteModal
        show={showInviteModal}
        toggle={() => setShowInviteModal(false)}
        onSuccess={() => queryClient.invalidateQueries('invitations')}
      />
    </PrivateLayout>
  );
};

export default Invitations;
