import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useSendInvite } from '../../../services/invitations';
import { useForm } from '../../../hooks/useForm';

const SendInviteModal = ({ show, toggle, onSuccess }) => {
  const { form, onChange } = useForm({
    email: '',
    message: '',
    userFirstName: '',
    userLastName: '',
  });

  const sendInvite = useSendInvite(
    () => {
      toggle();
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    sendInvite.mutate({ ...form });
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Send Invite</h3>
        <form id="invite-form" onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>User's Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={onChange}
              value={form.email}
              required
              placeholder="user@email.com"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>User's First Name</Form.Label>
            <Form.Control
              name="userFirstName"
              onChange={onChange}
              value={form.userFirstName}
              required
              placeholder="First"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>User's Last Name</Form.Label>
            <Form.Control
              name="userLastName"
              onChange={onChange}
              value={form.userLastName}
              required
              placeholder="Last"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Message To User (Optional)</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              onChange={onChange}
              value={form.message}
              placeholder="Optional Message"
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          form="invite-form"
          disabled={sendInvite.isLoading}
        >
          <FontAwesomeIcon
            icon={sendInvite.isLoading ? faSpinner : faCheck}
            spin={sendInvite.isLoading}
          />{' '}
          Send
        </Button>{' '}
        <Button variant="link" onClick={() => toggle()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendInviteModal;
