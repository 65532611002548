import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { components } from 'react-select';
import axios from 'axios';

import { ucwords } from '../../lib/strings';

const AdminSelect = ({
  value,
  isMulti = false,
  onChange,
  showDefaultOptions = false,
  usePublic = false,
  includeAllOption = false,
  style = {},
  required = false,
}) => {
  const Input = (props) => {
    return <components.Input {...props} />;
  };

  const Option = (props) => {
    const record = props.data.record;
    return (
      <components.Option {...props}>
        <p className="mb-0">{props.children}</p>
        {usePublic ? null : (
          <small className="text-muted">{ucwords(record.adminRole)}</small>
        )}
      </components.Option>
    );
  };

  const loadOptions = async (term) => {
    const endpoint = usePublic
      ? `/public/admins?search=${term}`
      : `/users?search=${term}&userType=admin`;
    const results = await axios.get(endpoint);
    const opts = [];

    if (includeAllOption) {
      opts.push({
        value: '__ALL__',
        label: 'All Directors & Admins',
        record: {
          adminRole: '',
        },
      });
    }

    results.data.rows.forEach((item) => {
      opts.push({
        value: item.userId,
        label: `${item.userLastName}, ${item.userFirstName}`,
        record: item,
      });
    });

    return opts;
  };
  return (
    <Async
      components={{ Option, Input }}
      isMulti={isMulti}
      isClearable={true}
      isSearchable={true}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      defaultOptions={showDefaultOptions}
      styles={style}
      required={required}
    />
  );
};

AdminSelect.propTypes = {
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showDefaultOptions: PropTypes.bool,
  style: PropTypes.object,
};

export default AdminSelect;
