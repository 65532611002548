export const ucwords = (str) => {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const basename = (file) => {
  const matches = [...file.matchAll(/\/(.*)\.(.*)$/g)];
  console.log('matches: ', matches);
  return matches.join('.');
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}
