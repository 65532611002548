import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import { useUser } from '../../contexts/UserContext';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import Loading from '../common/Loading';
import { useFetchAuthUri, useConvertAuthCode } from '../../services/quickbooks';

const Quickbooks = () => {
  const { updateUser } = useUser();
  const { qbAction } = useParams();
  const navigate = useNavigate();

  const convertAuthCode = useConvertAuthCode(
    () => {
      updateUser({ _isQuickbooksConnected: true }, () => {
        navigate('/');
      });
    },
    { onError: (err) => toast.error(err.message) }
  );

  const getAuthUri = useFetchAuthUri(
    (resp) => {
      window.location.href = resp.uri;
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const renderLaunch = () => {
    if (getAuthUri.isLoading) {
      return <Loading msg="Preparing connection request..." />;
    }

    return (
      <>
        <h3>Step 1: Authorize</h3>
        <p>Click the button below to connect your Quickbooks Online account.</p>
        <Button variant="primary" onClick={() => getAuthUri.mutate()}>
          Connect Quickbooks
        </Button>
      </>
    );
  };

  const renderCallback = () => {
    if (convertAuthCode.isLoading) {
      return <Loading msg="Authorizing your account..." />;
    }

    return (
      <>
        <h3>Step 2: Complete Connection</h3>
        <p>
          Thanks for authorizing our connection. Please click the button below
          to finalize the authorization process.
        </p>
        <Button
          variant="primary"
          onClick={() => {
            const searchParams = new URLSearchParams(
              window.location.search.substring(1)
            );
            const payload = {};

            for (const [key, value] of searchParams.entries()) {
              payload[key] = value;
            }

            convertAuthCode.mutate(payload);
          }}
        >
          Finalize Connection
        </Button>
      </>
    );
  };

  return (
    <PrivateLayout>
      <Panel className="pb-3">
        <h1>Quickbooks Integration</h1>
        <hr />

        {qbAction === 'callback' ? renderCallback() : null}
        {qbAction === 'launch' ? renderLaunch() : null}
      </Panel>
    </PrivateLayout>
  );
};

export default Quickbooks;
