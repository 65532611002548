import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop, getUrl } from '../lib/serviceHelpers';

export const useFetchInvitations = (page, search, sortBy, sortDir) => {
  return useQuery(
    ['invitations', { page, search, sortBy, sortDir }],
    async () => {
      const url = getUrl('/invitations', {
        page: page || 1,
        search,
        sortBy,
        sortDir,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useFetchAuthInvitation = (inviteId) => {
  return useQuery(
    ['invitations', { inviteId }],
    async () => {
      if (!inviteId) {
        return null;
      }

      const url = getUrl('/auth/invitation', {
        inviteId,
      });
      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useSendInvite = (callback, opts = {}) => {
  return useMutation(
    async (payload) => {
      const res = await axios.post(`/invitations`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useResendInvite = (callback, opts = {}) => {
  return useMutation(
    async (inviteId) => {
      const res = await axios.post(`/invitations/${inviteId}/resend`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useCheckReferralLink = (callback, opts = {}) => {
  return useMutation(
    async (id) => {
      const res = await axios.get(`/public/admins?id=${id}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
