import React, { createContext, useContext, useState } from 'react';
import { getCookie, setCookie, getCookieOpts } from '../lib/cookies';

const UserContext = createContext();

const DEFUALTS = {
  user: null,
  token: null,
  verifyId: null,
};

const getJsonItem = (key) => {
  const val = window.localStorage.getItem(key);
  if (!val) {
    return null;
  }
  return JSON.parse(val);
};

const UserProvider = ({ children }) => {
  const [context, _setContext] = useState({
    user: getJsonItem('user'),
    token: getCookie('token') || null,
    verifyId: window.sessionStorage.getItem('verifyId') || null,
  });

  const updateUser = (userData, callback = () => {}) => {
    const updates = { ...context, user: { ...context.user, ...userData } };

    window.localStorage.setItem('user', JSON.stringify(updates.user));

    _setContext(updates);
    callback();
  };

  const setVerifyId = (id) => {
    window.localStorage.clear();
    window.sessionStorage.setItem('verifyId', id);
    _setContext({ ...DEFUALTS, verifyId: id });
  };

  const signIn = (userData, callback = () => {}) => {
    const { _token, ...data } = userData;
    const opts = getCookieOpts();

    window.sessionStorage.clear();
    window.localStorage.setItem('user', JSON.stringify(data));
    setCookie('token', _token, opts);

    _setContext({
      user: data,
      token: _token,
      verifyId: null,
    });

    callback();
  };

  const signOut = (callback = () => {}) => {
    const opts = getCookieOpts();
    opts.maxAge = 0;

    window.localStorage.clear();
    window.sessionStorage.clear();

    setCookie('token', null, opts);

    _setContext({ ...DEFUALTS });
    callback();
  };

  const value = {
    user: context.user,
    token: context.token,
    verifyId: context.verifyId,
    updateUser,
    signIn,
    signOut,
    setVerifyId,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
