import React, { useEffect } from 'react';
import { Row, Col, Form, Table, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faSave,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useFetchVaForm, useUpdateVaForm } from '../../services/vaForms';
import { useForm } from '../../hooks/useForm';
import PrivateLayout from '../layouts/PrivateLayout';
import Panel from '../common/Panel';
import Tabs from '../common/Tabs';
import Loading from '../common/Loading';

const StyledLabel = styled(Form.Label)`
  display: block;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.6);
`;

const FormFieldMapper = () => {
  const { formId } = useParams();
  const { form, onChange, setValues } = useForm({
    formAdminScript: '',
    formClientHelp: '',
    formFieldsMap: {},
  });
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchVaForm(formId);

  const update = useUpdateVaForm(
    () => {
      queryClient.invalidateQueries(['va-forms']);
      toast.success('Your changes have been successfully saved.');
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    if (data) {
      setValues({
        formAdminScript: data.form.formAdminScript || '',
        formClientHelp: data.form.formClientHelp || '',
        formFieldsMap: data.formFieldsMap || {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = () => {
    update.mutate({
      formId,
      updates: {
        formAdminScript: form.formAdminScript || null,
        formClientHelp: form.formClientHelp || null,
        formFieldsMap: form.formFieldsMap || null,
      },
    });
  };

  const getFieldMapSel = (name) => {
    return (
      <Form.Select
        name={name}
        value={form.formFieldsMap[name]}
        onChange={(e) =>
          setValues({
            ...form,
            formFieldsMap: { ...form.formFieldsMap, [name]: e.target.value },
          })
        }
      >
        <option value="">None</option>
        <option value="user.userFirstName">User First Name</option>
        <option value="user.userLastName">User Last Name</option>
        <option value="user.userFullName">User Full Name</option>
        <option value="user.userEmail">User Email</option>
        <option value="user.userPhone">User Phone</option>
        <option value="user.userDateOfBirth">User Date of Birth</option>
        <option value="user.userSsn">User SSN</option>
        <option value="user.userCombatVeteran">User Is Combat Veteran</option>
        <option value="user.userIsVietnamVeteran">
          User Is Vietnam Veteran
        </option>
        <option value="user.userMarried">User Is Married</option>
        <option value="user.userDependentsUnder18">
          User # Dependents Under 18
        </option>
        <option value="claim.claimCurrentRating">User Current VA Rating</option>
        <option value="user.userMilitaryStatus">User Military Status</option>
        <option value="claim.claimDesiredRating">Claim Desired Rating</option>
        <option value="claim.claimCreatedOn">Claim Created On</option>
        <option value="claim.claimStatus">Claim Status</option>
        <option value="claim.claimVaType">Claim VA Type</option>
      </Form.Select>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <Row>
          <Col xs={12} sm={4}>
            <StyledLabel>Form Name</StyledLabel>
            <p>{data.form.formName}</p>
          </Col>
          <Col xs={12} sm={4}>
            <StyledLabel>File</StyledLabel>
            <p>{data.form.formFile}</p>
          </Col>
          <Col xs={12} sm={4}>
            <StyledLabel>&nbsp;</StyledLabel>
            <Button
              variant="primary"
              onClick={() => onSubmit()}
              disabled={update.isLoading}
            >
              <FontAwesomeIcon
                icon={update.isLoading ? faSpinner : faSave}
                spin={update.isLoading}
              />{' '}
              {update.isLoading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Col>
        </Row>
        <br />
        <br />

        <Tabs
          tabs={[
            {
              label: 'Scripts',
              content: (
                <div>
                  <Row>
                    <Col xs={12} sm={6}>
                      <h5>Director Script/Notes</h5>
                      <p>
                        What should the director/admin ask or comment on
                        concerning this form?
                      </p>
                      <Form.Control
                        as="textarea"
                        name="formAdminScript"
                        value={form.formAdminScript}
                        onChange={onChange}
                        placeholder="Notes, questions and script..."
                        style={{ minHeight: 250 }}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <h5>Client Script/Notes</h5>
                      <p>
                        Are there any tips for the client on how to answer or
                        summarize this question?
                      </p>
                      <Form.Control
                        as="textarea"
                        name="formClientHelp"
                        value={form.formClientHelp}
                        onChange={onChange}
                        placeholder="Notes and suggestions..."
                        style={{ minHeight: 250 }}
                      />
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              label: 'Field Maps',
              content: (
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>PDF Field</th>
                        <th>Field Type</th>
                        <th>Maps To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data._fields.map((field, fieldIdx) => {
                        return (
                          <tr key={fieldIdx}>
                            <td>{field.name}</td>
                            <td>{field.type}</td>
                            <td>{getFieldMapSel(field.name)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ),
            },
          ]}
        />
      </>
    );
  };

  return (
    <PrivateLayout>
      <Panel className="pb-3 mb-3">
        <div>
          <Link to="/admin/tools/va-forms">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        </div>
        <h2 className="mb-3">Form Editor</h2>
        {renderContent()}
      </Panel>
    </PrivateLayout>
  );
};

export default FormFieldMapper;
