import React from 'react';
import { useAVToggle } from '@100mslive/react-sdk';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneSlash,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/free-solid-svg-icons';

const Controls = ({ onEndCall }) => {
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  return (
    <div className="control-bar">
      <Button variant="secondary" className="btn-control" onClick={toggleAudio}>
        <FontAwesomeIcon
          icon={isLocalAudioEnabled ? faMicrophoneSlash : faMicrophone}
        />
      </Button>
      <Button
        variant="secondary"
        className="btn-control ms-1 me-1"
        onClick={toggleVideo}
      >
        <FontAwesomeIcon icon={isLocalVideoEnabled ? faVideoSlash : faVideo} />
      </Button>
      <Button
        variant="secondary"
        className="btn-control"
        onClick={() => {
          if (typeof onEndCall === 'function') {
            onEndCall();
          }
        }}
      >
        <FontAwesomeIcon icon={faPhoneSlash} />
      </Button>
    </div>
  );
};

export default Controls;
