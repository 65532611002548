import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronLeft,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';
import { useUser } from '../../contexts/UserContext';
import {
  useFetchQuestionnaireFields,
  useSubmitQuestionnaire,
} from '../../services/claims';

import QuestionnaireStep from './QuestionnaireStep';

const Questionnaire = () => {
  const { user } = useUser();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [notes, setNotes] = useState('');
  const [claimId, setClaimId] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    const saved = window.localStorage.getItem('_questionnaire');
    const newData = saved ? JSON.parse(saved) : {};

    const queryClaimId = searchParams.get('claimId');
    let _claimId = user._currentClaimId || null;
    if (!_claimId && queryClaimId) {
      setClaimId(queryClaimId);
    } else {
      setClaimId(_claimId);
    }

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveQuestionnaire = useSubmitQuestionnaire(
    () => {
      queryClient.invalidateQueries('current-claim');
      window.localStorage.removeItem('_questionnaire');
      navigate('/dashboard');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const { isLoading, data: questionnaireFields } = useFetchQuestionnaireFields({
    onSuccess: (res) => {
      if (Object.keys(data).length > 0) {
        let newStep = 0;
        res.forEach((q, qIdx) => {
          if (data[q.id]) {
            newStep = qIdx;
          }
        });
        setStep(newStep + 1);
      }
    },
  });

  const onNext = (vals, id) => {
    const newData = { ...data, [id]: vals };

    window.localStorage.setItem('_questionnaire', JSON.stringify(newData));

    setStep(step + 1);
    setData(newData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    saveQuestionnaire.mutate({
      claimId,
      payload: { ...data, notes },
    });
  };

  const render = () => {
    if (isLoading) {
      return (
        <Panel className="mb-3 pb-3 text-center">
          <h2>Loading...</h2>
        </Panel>
      );
    }

    // show the last question
    if (step === questionnaireFields.length - 1) {
      return (
        <form onSubmit={onSubmit}>
          <Panel className="mb-3 pb-3">
            <h5 className="mb-3">Is there anything else to add?</h5>
            <Form.Group>
              <Form.Control
                as="textarea"
                name="notes"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder="Any additional symptoms or conditions not covered?"
              />
            </Form.Group>
          </Panel>
          <Panel className="mb-3 pb-3">
            <Button
              variant="primary"
              onClick={() => setStep(step - 1)}
              className="me-3"
              disabled={saveQuestionnaire.isLoading}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={saveQuestionnaire.isLoading}
            >
              Finish{' '}
              <FontAwesomeIcon
                icon={saveQuestionnaire.isLoading ? faSpinner : faCheck}
                spin={saveQuestionnaire.isLoading}
              />
            </Button>
          </Panel>
        </form>
      );
    }

    const id = questionnaireFields[step].id;
    const vals = data[id] || {};

    return (
      <QuestionnaireStep
        question={questionnaireFields[step]}
        values={vals}
        onNext={onNext}
        onBack={step === 0 ? null : () => setStep(step - 1)}
      />
    );
  };

  return (
    <PublicLayout>
      <Row>
        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
          <Panel className="mb-3 pb-3 text-center">
            <h3>Preliminary Information and Symptoms</h3>
            <p>
              Complete the questionnaire below to provide us with more details.
            </p>
            {questionnaireFields ? (
              <h5>
                Step {step + 1} of {questionnaireFields.length}
              </h5>
            ) : null}
          </Panel>

          {render()}
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default Questionnaire;
