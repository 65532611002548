import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const TabContainer = styled.div`
  width: 100%;

  & > .tab-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  & > .tab-links:before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    height: 1px;
    background: #999;
    z-index: 0;
  }

  & > .tab-links > button {
    display: block;
    margin: 0;
    padding: 0.5rem;
    color: #333;
    text-decoration: none;
    border-radius: 0;
    font-size: 1.25rem;
    font-weight: 300;
    z-index: 1;
  }

  & > .tab-links > button.selected {
    font-weight: 500;
    border-bottom: 3px solid #333;
  }
`;

const Tabs = ({ tabs, defaultSelectedIndex = 0 }) => {
  const [selected, setSelected] = useState(defaultSelectedIndex);

  return (
    <TabContainer>
      <div className="tab-links">
        {tabs.map((tab, idx) => {
          return (
            <Button
              key={idx}
              variant="link"
              onClick={() => setSelected(idx)}
              className={selected === idx ? 'selected' : ''}
            >
              {tab.label}
            </Button>
          );
        })}
      </div>
      {tabs[selected].content}
    </TabContainer>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  defaultSelectedIndex: PropTypes.number,
};

export default Tabs;
