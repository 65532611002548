import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const FolderPath = ({ folder, onClick }) => {
  const folderItem = folder ? folder : { label: '/', path: null };

  return (
    <Breadcrumb>
      {folderItem.path ? (
        <Breadcrumb.Item onClick={() => onClick(null)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Breadcrumb.Item>
      ) : null}
      <Breadcrumb.Item active>{folderItem.label}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default FolderPath;
