import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

import GeneratedContentViewer from './GeneratedContentViewer';
import { useReprocessAddon } from '../../../services/claims';

const GeneratedContentTab = ({ claim, addons, questionnaireMatrix }) => {
  const [addon, setAddon] = useState(null);

  const reprocessAddon = useReprocessAddon(
    () => {
      toast.success(
        'Your generated content has been queued for re-processing. Please check back in a few moments to see the updated content.'
      );
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          {!addons || addons.length === 0 ? (
            <p className="text-center lead">
              No generated content is available.
            </p>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Length</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {addons.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {moment(row.claimAddonUpdatedOn).format(
                          'MM/DD/YYYY h:mm A'
                        )}
                      </td>
                      <td>{row.claimAddonType}</td>
                      <td>
                        {row.claimAddonContent.content
                          ? row.claimAddonContent.content.length
                          : 'N/A'}
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => setAddon(row)}
                          className="me-1"
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            fixedWidth
                          />
                        </Button>
                        {/^ai:statement/.test(row.claimAddonType) ? (
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure? You will not be able to recover this version.'
                                )
                              ) {
                                reprocessAddon.mutate({
                                  claimId: claim.claimId,
                                  addonId: row.claimAddonId,
                                });
                              }
                            }}
                            disabled={reprocessAddon.isLoading}
                          >
                            <FontAwesomeIcon icon={faArrowsRotate} fixedWidth />
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      <GeneratedContentViewer
        show={addon !== null}
        toggle={() => setAddon(null)}
        addon={addon}
      />
    </>
  );
};

export default GeneratedContentTab;
