import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

import { useUser } from '../../contexts/UserContext';
import { getCookie } from '../../lib/cookies';

import Home from '../pages/Home';
import Login from '../auth/Login';
import Verify from '../auth/Verify';
import Register from '../auth/Register';
import ReferralLink from '../auth/ReferralLink';
import PartnerLink from '../auth/PartnerLink';

import Dashboard from '../dashboard/Dashboard';
import Users from '../users/Users';
import Admins from '../users/Admins';
import Invitations from '../users/Invitations';
import Providers from '../users/Providers';
import ClaimDetails from '../claim/ClaimDetails';
import Profile from '../profile/Profile';
import Call from '../calls/Call';
import Questionnaire from '../questionnaire/Questionnaire';
import Quickbooks from '../quickbooks/Quickbooks';
import Calendar from '../calendar/Calendar';
import VaForms from '../tools/VaForms';
import FormFieldMapper from '../tools/FormFieldMapper';
import Partners from '../partners/Partners';
import LeadForm from '../leadForm/leadForm';

import EtchCallback from '../callbacks/EtchCallback';

import PrivateRoute from './PrivateRoute';

const MainRouter = () => {
  const { signOut } = useUser();
  const urlReg = new RegExp(`^/.*`);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use(
    function (config) {
      // we use the token here instead of from useUser above because of
      // a caching issue with the function
      const token = getCookie('token') || null;

      if (token && urlReg.test(config.url)) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      config.headers['X-USER-TIMEZONE'] = moment.tz.guess();

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err.response.data && err.response.data.msg) {
        err.message = err.response.data.msg;
      }

      if (err.response.status === 401 || err.response.status === 403) {
        signOut();
      }

      return Promise.reject(err);
    }
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/referral/:referralId" element={<ReferralLink />} />
        <Route path="/partner/:partnerReferralUrl" element={<PartnerLink />} />
        <Route path='/leads' element={<LeadForm />} />

        <Route path="/dashboard" element={<PrivateRoute as={Dashboard} />} />
        <Route
          path="/questionnaire"
          element={<PrivateRoute as={Questionnaire} />}
        />
        <Route path="/meeting/:inviteId" element={<PrivateRoute as={Call} />} />
        <Route
          path="/calendar"
          element={<PrivateRoute as={Calendar} admin={true} provider={true} />}
        />
        <Route
          path="/callback/:fileId"
          element={<PrivateRoute as={EtchCallback} />}
        />
        <Route
          path="/admin/users"
          element={<PrivateRoute as={Users} admin={true} />}
        />
        <Route
          path="/admin/admins"
          element={<PrivateRoute as={Admins} admin={true} />}
        />
        <Route
          path="/admin/partners"
          element={<PrivateRoute as={Partners} admin={true} />}
        />
        <Route
          path="/admin/invitations"
          element={<PrivateRoute as={Invitations} admin={true} />}
        />
        <Route
          path="/admin/providers"
          element={<PrivateRoute as={Providers} admin={true} />}
        />
        <Route
          path="/admin/profile/:claimId"
          element={<PrivateRoute as={Profile} admin={true} provider={true} />}
        />
        <Route
          path="/admin/claim/:claimId"
          element={
            <PrivateRoute as={ClaimDetails} admin={true} provider={true} />
          }
        />
        <Route
          path="/admin/tools/va-forms/mapper/:formId"
          element={
            <PrivateRoute as={FormFieldMapper} admin={true} provider={false} />
          }
        />
        <Route
          path="/admin/tools/va-forms"
          element={<PrivateRoute as={VaForms} admin={true} provider={false} />}
        />
        <Route
          path="/quickbooks/:qbAction"
          element={<PrivateRoute as={Quickbooks} admin={true} />}
        />

        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
