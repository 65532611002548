import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useFetchDashboard = () => {
  return useQuery(
    ['dashboard'],
    async () => {
      const res = await axios.get('/dashboard');
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};
