import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { components } from 'react-select';
import axios from 'axios';

import { ucwords } from '../../lib/strings';

const UserSelect = ({
  value,
  userType,
  onChange,
  isMulti = false,
  showDefaultOptions = false,
  style = {},
}) => {
  const Input = (props) => {
    return <components.Input {...props} />;
  };

  const Option = (props) => {
    const record = props.data.record;
    return (
      <components.Option {...props}>
        <p className="mb-0">{props.children}</p>
        <small className="text-muted">
          {ucwords(record.userType)}
          {record.userType === 'provider' && record.userState
            ? ` / ${record.userState}`
            : ''}
        </small>
      </components.Option>
    );
  };

  const loadOptions = async (term) => {
    const endpoint = `/users?search=${term}&userType=${userType}`;
    const results = await axios.get(endpoint);
    const opts = results.data.rows.map((item) => {
      return {
        value: item.userId,
        label: `${item.userLastName}, ${item.userFirstName}`,
        record: item,
      };
    });

    return opts;
  };
  return (
    <Async
      components={{ Option, Input }}
      isMulti={isMulti}
      isClearable={true}
      isSearchable={true}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      defaultOptions={showDefaultOptions}
      styles={style}
    />
  );
};

UserSelect.propTypes = {
  value: PropTypes.any,
  userType: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showDefaultOptions: PropTypes.bool,
  style: PropTypes.object,
};

export default UserSelect;
