import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useUser } from '../../contexts/UserContext';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import ErrorFallback from '../errorBoundary/ErrorFallback';

import './themes/Primary.css';

const StyledLayout = styled.div`
  background: rgb(191, 191, 191);
`;

const StyledHeader = styled.header`
  margin: 0;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.9);
`;

const StyledNavWrapper = styled.div`
  text-align: right;

  @media screen and (max-width: 430px) {
    display: none;
  }
`;

const StyledNavButton = styled(Button)`
  color: #fff !important;
`;

const StyledNavSelect = styled.div`
  display: none;

  @media screen and (max-width: 430px) {
    display: block;
    margin: 1rem 0;
  }
`;

const USER_LINKS = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Calendar',
    url: '/calendar',
  },
  {
    label: 'Invitations',
    url: '/admin/invitations',
  },
];

const ADMIN_LINKS = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Calendar',
    url: '/calendar',
  },
  {
    label: 'Invitations',
    url: '/admin/invitations',
  },
  {
    label: 'Users',
    url: '/admin/users',
  },
  {
    label: 'Providers',
    url: '/admin/providers',
  },
  {
    label: 'Admins',
    url: '/admin/admins',
  },
  {
    label: 'Partners',
    url: '/admin/partners',
  },
];

const PrivateLayout = ({ children }) => {
  const { user, signOut } = useUser();
  const navigate = useNavigate();

  const renderList = (list) => {
    return list.map((row, idx) => {
      return (
        <StyledNavButton
          key={idx}
          variant="link"
          onClick={() => navigate(row.url)}
        >
          {row.label}
        </StyledNavButton>
      );
    });
  };

  const renderNav = () => {
    switch (true) {
      case user._admin && user._admin.adminRole === 'director':
        return renderList(USER_LINKS);

      case user._admin && user._admin.adminRole === 'admin':
        return renderList(ADMIN_LINKS);

      default:
        return null;
    }
  };

  const renderSelect = () => {
    let list = null;

    switch (true) {
      case user._admin && user._admin.adminRole === 'director':
        list = USER_LINKS;
        break;

      case user._admin && user._admin.adminRole === 'admin':
        list = ADMIN_LINKS;
        break;

      default:
        return null;
    }

    return (
      <Form.Group>
        <Form.Select
          onChange={(e) => navigate(e.target.value)}
          value={window.location.pathname}
        >
          {list.map((row, idx) => {
            return (
              <option key={idx} value={row.url}>
                {row.label}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
  };

  return (
    <StyledLayout>
      <StyledHeader>
        <Container fluid>
          <Row>
            <Col>
              <img
                src={
                  process.env.PUBLIC_URL + '/VR-Full-White-Horizontal-01.png'
                }
                alt="Veritas Ratings"
                title="Veritas Ratings"
                style={{ height: 120 }}
              />
            </Col>
            <Col className="pt-5">
              <StyledNavWrapper>
                {renderNav()}
                <StyledNavButton variant="link" onClick={() => signOut()}>
                  Sign Out
                </StyledNavButton>
              </StyledNavWrapper>
            </Col>
          </Row>
          <StyledNavSelect>{renderSelect()}</StyledNavSelect>
        </Container>
      </StyledHeader>
      <section>
        <ErrorBoundary fallback={<ErrorFallback />}>
          <Container fluid>{children}</Container>
        </ErrorBoundary>
      </section>
      <footer>
        <p className="text-center mt-5">
          &copy; {dayjs().format('YYYY')} Veritas Ratings &middot; All Rights
          Reserved &middot; <Link to="/privacy">Privacy Policy</Link> &middot;{' '}
          <Link to="/terms">Terms of Use</Link>
        </p>
      </footer>
    </StyledLayout>
  );
};

export default PrivateLayout;
