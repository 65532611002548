import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../common/Loading';
import { useFetchQuestionnaireFields } from '../../services/claims';

const StyledWrapper = styled.div`
  background: #dfdfdf;
  border-radius: 5px;
  padding: 1rem;
  height: 100%;
`;

const QuestionnaireResults = ({ claimId, answers }) => {
  const { isLoading, data: questionnaire } = useFetchQuestionnaireFields();

  const renderSelections = (answer, question) => {
    if (
      !question ||
      !question.subQuestions ||
      question.subQuestions.length === 0
    ) {
      return null;
    }

    return question.subQuestions
      .map((s) => {
        if (!answer.selections[s.id]) {
          return `${s.quesion}: N/A`;
        }

        return `${s.question}: ${answer.selections[s.id]}`;
      })
      .join('; ');
  };

  if (isLoading) {
    return <Loading msg="Loading Questionnaire..." />;
  }

  if (!answers) {
    return (
      <Alert variant="info" className="text-center">
        <h4>It looks like you have not completed your questionnaire.</h4>
        <p>
          <Link to={`/questionnaire?claimId=${claimId}`}>
            Complete your questionnaire here.
          </Link>
        </p>
      </Alert>
    );
  }

  return (
    <>
      <Row>
        {questionnaire.map((question, qIdx) => {
          const answer = answers[question.id] || {
            value: 'Unanswered',
            selections: {},
          };

          return (
            <Col key={qIdx} xs={12} sm={6} className="mb-3">
              <StyledWrapper>
                <p>
                  <strong>Q.</strong> {question.question}
                </p>
                <p>
                  <strong>A.</strong> {answer.value}
                </p>
                {answer.value === 'Yes'
                  ? renderSelections(answer, question)
                  : null}
              </StyledWrapper>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default QuestionnaireResults;
