import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 2rem 0;
`;

const Loading = ({ msg = 'Loading...' }) => {
  return (
    <LoadingWrapper>
      <h1>
        <FontAwesomeIcon icon={faCircleNotch} spin /> {msg}
      </h1>
    </LoadingWrapper>
  );
};

export default Loading;
