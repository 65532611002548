import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useGenerateReport = (reportName) => {
  return useQuery(
    ['reports', reportName],
    async () => {
      const res = await axios.post(`/reports/run?reportName=${reportName}`, {
      });
      return res.data;
    },
    {
      enabled: !!reportName,
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};