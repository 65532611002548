import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../../hooks/useForm';
import {
  useCreateClaimActivity,
  useUpdateClaimActivity,
} from '../../../services/activity';

const DEFAULT_VALUES = {
  actType: 'note',
  actComments: '',
  actCreatedOn: moment().format('YYYY-MM-DD'),
  actReminderOn: '',
};

const ActivityModal = ({ claimId, activity, show, toggle }) => {
  const { form, onChange, setValues } = useForm({ ...DEFAULT_VALUES });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (activity && activity.actId !== 'new') {
      setValues({
        ...DEFAULT_VALUES,
        ...activity,
        actCreatedOn: moment(activity.actCreatedOn).format('YYYY-MM-DD'),
        actReminderOn: activity.actReminderOn
          ? moment.utc(activity.actReminderOn).format('YYYY-MM-DD')
          : '',
      });
    } else {
      setValues({ ...DEFAULT_VALUES });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const createClaimActivity = useCreateClaimActivity(() => {
    toast.success('The selected activity was successfully created.');
    queryClient.invalidateQueries({ queryKey: ['claim-profile'] });
    toggle();
  });

  const updateClaimActivity = useUpdateClaimActivity(() => {
    toast.success('The selected activity was successfully updated.');
    queryClient.invalidateQueries({ queryKey: ['claim-profile'] });
    toggle();
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (activity && activity.actId !== 'new') {
      updateClaimActivity.mutate({
        claimId,
        actId: activity.actId,
        payload: { ...form },
      });
    } else {
      createClaimActivity.mutate({
        claimId,
        payload: { ...form },
      });
    }
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Activity Type</Form.Label>
            <Form.Select
              name="actType"
              value={form.actType}
              onChange={onChange}
            >
              <option value="note">Note</option>
              <option value="call">Call</option>
              <option value="email">Email</option>
              <option value="reminder">Reminder</option>
              <option value="task">Task</option>
              <option value="other">Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Activity Date</Form.Label>
            <Form.Control
              type="date"
              format="MM/DD/YYYY"
              name="actCreatedOn"
              value={form.actCreatedOn}
              onChange={onChange}
              max={DEFAULT_VALUES.actCreatedOn}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              name="actComments"
              value={form.actComments}
              onChange={onChange}
              style={{ minHeight: 200 }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Reminder (Optional)</Form.Label>
            <Form.Control
              type="date"
              format="MM/DD/YYYY"
              name="actReminderOn"
              value={form.actReminderOn}
              onChange={onChange}
              required={form.actType === 'reminder'}
            />
            <small className="text-muted">
              We will send you an email the morning of the selected date.
            </small>
          </Form.Group>
          <hr />
          <div>
            <Button type="submit" variant="primary">
              <FontAwesomeIcon icon={faCheck} fixedWidth /> Save Activity
            </Button>
            <Button variant="link" onClick={() => toggle()}>
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ActivityModal.propTypes = {
  claimId: PropTypes.string.isRequired,
  activity: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ActivityModal;
