import React from 'react';
import { Tab, Tabs as ReactTabs } from 'react-bootstrap';

const getDefaultTab = (tabs, defaultTab) => {
  if (defaultTab) {
    return defaultTab;
  }

  return tabs[0].key || 'tab-0';
};

const Tabs = ({ tabs, defaultTab }) => {
  return (
    <ReactTabs
      defaultActiveKey={getDefaultTab(tabs, defaultTab)}
      id="tabs"
      className="mb-3"
    >
      {tabs.map((t, tIdx) => {
        return (
          <Tab key={tIdx} eventKey={t.key || `tab-${tIdx}`} title={t.label}>
            {t.content}
          </Tab>
        );
      })}
    </ReactTabs>
  );
};

export default Tabs;
